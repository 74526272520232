const initialState = {
  electricalList: [],
  loaded: false
}

export const electricalReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_ELECTRICAL': return {
      ...state,
      electricalList: action.payload
    }
    case 'ELECTRICAL_LOADED': return {
      ...state,
      loaded: action.payload
    }
    default: return state
  }
}