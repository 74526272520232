import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Input, Layout, Menu, Modal, Form, Divider, message } from 'antd';
import { Link } from 'react-router-dom';
import { LogoutOutlined, KeyOutlined, CopyFilled } from '@ant-design/icons';
import { updateLog, updateUser } from '../redux/actions/userActions'

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

function DefaultLayout(props) {
  const allConstants = useSelector(state => state.usersReducer).constants;

  const [menuList, setMenuList] = useState([]);
  const [oldPw, setOldPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'))
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // setIsModalVisible(false);
    console.log('oldPw', oldPw)
    console.log('newPw', newPw)
    if (user.password === oldPw) {
      dispatch(updateUser({ ...user, password: newPw }, true));
      localStorage.setItem('user', JSON.stringify({ ...user, password: newPw }))
      setIsModalVisible(false)
      setNewPw('')
      setOldPw('')
    } else {
      message.error("Current password is incorrect");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logout = () => {
    // update the log logout time before logging out
    if(user){
      dispatch(updateLog({
        userId: user._id,
        name: user.username,
        email: user.email,
        logout: true
      }))
    }
  }

  const changePassword = () => {
    showModal();
  }

  useEffect(() => {
    if (allConstants.length) {
      let menu = allConstants?.find(x => x.text === "Menu")?.value ?? [];

      setMenuList(user && user.isAdmin ? menu : menu.filter(x => user.menu.length && user.menu.some(y => y.name === x.name)));
    }
  }, [allConstants])

  return (
    <Layout className="layout">
      <Header>

        <div className='logo'>
          <div className='companyName'>Du North Brampton</div>
        </div>

        <Menu theme="light" mode="horizontal" >

          {menuList.length > 0 && menuList.map((x, i) => {
            return (
              <Menu.Item key={`${i}`} >
                <Link to={x.route}>{x.name}</Link>
              </Menu.Item>
            )
          })}

          {user.isAdmin && <Menu.Item key="/users" >
            <Link to="/users">Users</Link>
          </Menu.Item>}

          <SubMenu key="SubMenu" title={`Settings (${user.username})`}>
            <Menu.Item>
              <Link to="/constants">Constants</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/formulas">Formulas</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/cycle-times">Cycle Times</Link>
            </Menu.Item>
            <Menu.Item icon={<KeyOutlined />} >
              <a onClick={changePassword}>Change Password</a>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />}>
              <a onClick={logout}>Logout</a>
            </Menu.Item>
          </SubMenu>

        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">{props.children}</div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Copyright © {(new Date()).getFullYear()}. All Rights Reserved. </Footer>

      <Modal title="Change Password" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Input.Password placeholder='Current Password' value={oldPw} onChange={(e) => setOldPw(e.target.value)} />
        <Divider />
        <Input.Password placeholder='New Password' value={newPw} onChange={(e) => setNewPw(e.target.value)} />
      </Modal>
    </Layout>
  )
}

export default DefaultLayout
