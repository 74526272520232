import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal, Input, Tabs } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'
import { importExcel } from "../redux/actions/rucActions";
import moment from 'moment';
import Filter from '../components/Filter';
import { Parser } from 'json2csv';

const { TabPane } = Tabs;

function ViewShipping(props) {
  const allShippings = useSelector(state => state.shippingReducer).shippingList;
  const allFTShippings = useSelector(state => state.shippingReducer).ftShippingList;
  const averageShippings = [];
  const averageFTShippings = [];
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))

  const history = useHistory()

  const [deleteId, setDeleteId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [fTdataSource, setFTDataSource] = useState([]);
  const [filteredFTDataSource, setFilteredFTDataSource] = useState([]);
  const [averageShippingDataSource, setAverageShippingDataSource] = useState([]);
  const [averageShippingFTDataSource, setAverageShippingFTDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [inputKey, setInputKey] = useState(Date.now());

  const columns = [
    {
      title: 'Shipping Date',
      dataIndex: "shippingDate",
    },
    {
      title: 'PO#',
      dataIndex: "po",
    },
    {
      title: 'QTY Shipped',
      dataIndex: "qtyShippied",
    },
    {
      title: 'Extended price of shipment',
      dataIndex: "extendedPrice",
    },
    {
      title: 'Comment',
      dataIndex: "comment",
    },
    {
      title: 'Actions',
      render: (text, data) => {
        return <div className="flex" key={data._id}>
          {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "shipping").rights.some(x => x.toLowerCase() === 'edit')) &&
            <EditOutlined onClick={() => { history.push(`/shipping/edit/${data._id}`) }} />}
          {/* <DeleteOutlined onClick={() => onDelete(data.id)} style={{ color: '#db2727', marginLeft: 10 }} /> */}
        </div>
      },
    },
  ];

  const ftColumns = [
    {
      title: 'Shipping Date',
      dataIndex: "shippingDate",
    },
    {
      title: 'PO#',
      dataIndex: "po",
    },
    {
      title: 'QTY Shipped',
      dataIndex: "qtyShippied",
    },
    {
      title: 'Extended price of shipment',
      dataIndex: "extendedPrice",
    },
    {
      title: 'Comment',
      dataIndex: "comment",
    },
    {
      title: 'Order Date',
      dataIndex: "orderDate",
    },
    {
      title: 'Turn Around Time',
      dataIndex: "tat",
    },
    {
      title: 'Actions',
      render: (text, data) => {
        return <div className="flex" key={data._id}>
          {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "shipping").rights.some(x => x.toLowerCase() === 'edit')) &&
            <EditOutlined onClick={() => { history.push(`/shipping-ft/edit/${data._id}`) }} />}
          {/* <DeleteOutlined onClick={() => onDelete(data.id)} style={{ color: '#db2727', marginLeft: 10 }} /> */}
        </div>
      },
    },
  ];

  // Average Shipping Columns  
  const averageShippingColumns = [
    {
      title: 'Shipping Date',
      dataIndex: "shippingDate",
    },
    {
      title: 'QTY Shipped',
      dataIndex: "qtyShippied",
    },
    {
      title: 'Extended price of shipment',
      dataIndex: "extendedPrice",
    },
  ];

  // Average FT Shipping Columns  
  const averageFTShippingColumns = [
    {
      title: 'Shipping Date',
      dataIndex: "shippingDate",
    },
    {
      title: 'QTY Shipped',
      dataIndex: "qtyShippied",
    },
    {
      title: 'Extended price of shipment',
      dataIndex: "extendedPrice",
    },
  ];

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  // All Shippings data
  useEffect(() => {
    if (allShippings.length && !dataSource.length) {
      const shipping = allShippings.filter(x => x != null && x.date != null).map(x => {
        return {
          ...x,
          extendedPrice: formatter.format(x.extendedPrice),
          // shippingDate: moment(x.date).format('ddd, MMMM Do YYYY') + ' ' + (x.time ?? "9:00 AM"),
          shippingDate: moment(x.date).format('ddd, MMMM Do YYYY')
        }
      }).sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
      // shipping.forEach(s => console.log(s, new Date(s.date).toDateString()))
      setDataSource(shipping);
      setFilteredDataSource(shipping);
    }
  }, [allShippings])

  // All FT (Fast Track) Shippings data
  useEffect(() => {
    if (allFTShippings.length && !fTdataSource.length) {
      const shipping = allFTShippings.filter(x => x != null && x.date != null).map(x => {
        const combineShippingDate = moment((moment(x.date).format('MM/DD/YYYY') + ' ' + (x.time ?? "9:00 AM")), 'MM/DD/YYYY hh:mm A');
        const combineOrderDate = moment((moment(x.orderDate).format('MM/DD/YYYY') + ' ' + x.orderTime), 'MM/DD/YYYY hh:mm A');

        return {
          ...x,
          extendedPrice: formatter.format(x.extendedPrice),
          // shippingDate: moment(x.date).format('ddd, MMMM Do YYYY') + ' ' + (x.time ?? "9:00 AM"),
          shippingDate: moment(x.date).format('ddd, MMMM Do YYYY'),
          orderDate: moment(x.orderDate).format('ddd, MMMM Do YYYY') + ' ' + x.orderTime,
          tat: secondsToDhms(combineShippingDate.diff(combineOrderDate, 'seconds'))
        }
      }).sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
      setFTDataSource(shipping);
      setFilteredFTDataSource(shipping);
    }
  }, [allFTShippings])

  // All Average Shippings data
  useEffect(() => {
    getAverageShippingData(allShippings, 'shipping');
  }, [averageShippings])

  // All Average FT Shippings data
  useEffect(() => {
    getAverageShippingData(allFTShippings, 'FTshipping');
  }, [averageFTShippings])

  // method to handle the sum of all the rows in shipping & FT shipping table
  /**
   * @param {the array of all the rows in the table} data 
   * @param {the type of the data (can be shipping and FT shipping)} type 
   */
  function getAverageShippingData(data, type) {
    // console.log(data.length, type);
    if (data.length && !dataSource.length) {
      let averageShippingData = parseAverageShippingData(data);
      if(type === 'shipping'){
        setAverageShippingDataSource(averageShippingData);
      }
      else if(type === 'FTshipping'){
        setAverageShippingFTDataSource(averageShippingData);
      }
    }
  }

  function parseAverageShippingData(data){
    const shipping = data.filter(x => x != null && x.date != null).map(x => {
      return {
        ...x,
        extendedPrice: x.extendedPrice,
        // shippingDate: moment(x.date).format('ddd, MMMM Do YYYY') + ' ' + (x.time ?? "9:00 AM")
        shippingDate: moment(x.date).format('ddd, MMMM Do YYYY')
      }
    }).sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
    // get the unique dates 
    const uniqueDates = [];
    for (let i = 0; i < shipping.length; i++) {
      let d = new Date(shipping[i].date);
      let dateOnly = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
      if (uniqueDates.indexOf(dateOnly) === -1) {
        uniqueDates.push(dateOnly);
      }
    } 
    // now we have the unique dates in uniqueDates array
    // we need to get sum of columns based on unique dates
    const averageShippingData = [];
    for (let i = 0; i < uniqueDates.length; i++) {
      let qtyShippiedSum = 0;
      let extendedPriceSum = 0;
      let date = null;
      let mainDate = null;
      for (let j = 0; j < shipping.length; j++) {
        let d = new Date(shipping[j].date);
        let dateOnly = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
        if (uniqueDates[i] === dateOnly) {
          mainDate = shipping[j].date;
          qtyShippiedSum += shipping[j].qtyShippied;
          extendedPriceSum += shipping[j].extendedPrice;
          // date = moment(shipping[j].date).format('ddd, MMMM Do YYYY') + ' ' + (shipping[j].time ?? "9:00 AM");
          date = moment(shipping[j].date).format('ddd, MMMM Do YYYY');
        }
      }
      averageShippingData.push({
        shippingDate: date,
        date: mainDate,
        qtyShippied: qtyShippiedSum,
        extendedPrice: formatter.format(extendedPriceSum, 2)
      });
    }
    return averageShippingData;
  }

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);

    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
    return dDisplay + hDisplay;
  }

  const handleOk = () => {
    let data = new FormData();
    data.append('type', "ruc");
    data.append('postedBy', JSON.parse(localStorage.getItem('user'))._id);
    data.append('uploadfile', excelFile);
    dispatch(importExcel(data));
    setIsModalVisible(false);
    setExcelFile();
    setInputKey(Date.now())
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setExcelFile();
    setInputKey(Date.now())
  };

  /**
   * @param {the parent array} array 
   * @param {the value to be checked if the array is having the value or not} value 
   * @returns boolean
   */ 
  function checkIfArrayHasValue(array, value) {
    return array.indexOf(value) === -1 ? false : true;
  }

  const onFilter = (params) => {
    var ds = [];
    var averageShipping = [];
    var averageFTShipping = [];
    getAverageShippingData(allShippings, 'shipping');
    getAverageShippingData(allFTShippings, 'FTshipping');
    switch (params.filterBy.toLowerCase()) {
      case "date":
        const filterDate = moment(params.date).format('MM/DD/YYYY');
        ds = dataSource.filter(x => isValidDate(new Date(x.date)) && filterDate == moment(x.date).format('MM/DD/YYYY'));
        averageShipping = parseAverageShippingData(allShippings).filter(x => isValidDate(new Date(x.date)) && filterDate == moment(x.date).format('MM/DD/YYYY'));
        averageFTShipping = parseAverageShippingData(allFTShippings).filter(x => isValidDate(new Date(x.date)) && filterDate == moment(x.date).format('MM/DD/YYYY'));
        break;
      case "weekno":
        let firstDate = new Date(moment(params.firstday).format('MM/DD/YYYY'))
        let lastDate = new Date(moment(params.lastday).format('MM/DD/YYYY'))
        firstDate.setFullYear(params.year);
        lastDate.setFullYear(params.year);
        ds = dataSource.filter(x => isValidDate(new Date(x.date)) && firstDate.getTime() <= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()) &&
            lastDate.getTime() >= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime())
          );
        averageShipping = parseAverageShippingData(allShippings).filter(x => isValidDate(new Date(x.date)) && firstDate.getTime() <= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()) && lastDate.getTime() >= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()));
        averageFTShippings = parseAverageShippingData(allFTShippings).filter(x => isValidDate(new Date(x.date)) && firstDate.getTime() <= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()) && lastDate.getTime() >= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()));
        break;
      case "month":
        var date = new Date();
        date.setMonth(params.month);
        date.setFullYear(params.year);
        ds = dataSource
          .filter(x => isValidDate(new Date(x.date)) && moment(date).format('MM/YY') === moment(x.date).format('MM/YY'))
        averageShipping = parseAverageShippingData(allShippings).filter(x => isValidDate(new Date(x.date)) && moment(date).format('MM/YY') === moment(x.date).format('MM/YY'))
        averageFTShipping = parseAverageShippingData(allFTShippings).filter(x => isValidDate(new Date(x.date)) && moment(date).format('MM/YY') === moment(x.date).format('MM/YY'))
        break;
      default:
        break;
    }

    setFilteredDataSource(ds);
    setAverageShippingDataSource(averageShipping);
    setAverageShippingFTDataSource(averageFTShipping);
  }

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  const exportData = async () => {
    try {
        filteredDataSource.map(x => {
            delete x._id;
            delete x.createdAt;
            delete x.updatedAt;
        })
        const parser = new Parser({});
        let csv = parser.parse(filteredDataSource);
        var data, filename, link;
        if (csv == null) return;
        filename = `shipping_data_${moment().format('MM-DD-YYYY')}.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        console.error(err);
    }
}

  return (
    <div>
      {props.isDashboard ?
        <div>
          <Filter
            onReset={() => {
              setFilteredDataSource(dataSource);
              setAverageShippingDataSource(parseAverageShippingData(allShippings));
              setAverageShippingFTDataSource(parseAverageShippingData(allFTShippings));
            }}
            onFilter={onFilter}
          />
          <Table columns={props.isFastTracking ? ftColumns.slice(0, ftColumns.length - 1) 
          : props.isShippingByDate ? averageShippingColumns 
          : props.isFTShippingByDate ? averageFTShippingColumns
          : columns.slice(0, columns.length - 1)} 
          dataSource={props.isFastTracking ? filteredFTDataSource 
            : props.isShippingByDate ? averageShippingDataSource 
            : props.isFTShippingByDate ? averageShippingFTDataSource
            : filteredDataSource} scroll={{ x: 1300 }} />
        </div>
        : <DefaultLayout>

          <div className='d-flex justify-content-between'>
            <h3>Shipping</h3>
            {/* {user.isAdmin && <Button type="primary" onClick={showModal}><FileExcelOutlined /> Import Excel</Button>} */}
          </div>

          <Filter
            onReset={() => {
              setFilteredDataSource(dataSource);
              setAverageShippingDataSource(parseAverageShippingData(allShippings));
              setAverageShippingFTDataSource(parseAverageShippingData(allFTShippings));
            }}
            onFilter={onFilter}
          />

          <Tabs defaultActiveKey="1">
            <TabPane tab="Shipping" key="1">
              {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "shipping").rights.some(x => x.toLowerCase() === 'write')) &&
                <div className="d-flex justify-content-end">
                  <Link to='/shipping/post' className='addBtn'>Add Shipping</Link>
                </div>}


              <Table columns={columns} dataSource={filteredDataSource} scroll={{ x: 1300 }} />
            </TabPane>

            <TabPane tab="Fast Track Shipping" key="2">
              {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "shipping").rights.some(x => x.toLowerCase() === 'write')) &&
                <div className="d-flex justify-content-end">
                  <Link to='/shipping-ft/post' className='addBtn'>Add Fast Track Shipping</Link>
                </div>}
              <Table columns={ftColumns} dataSource={filteredFTDataSource} scroll={{ x: 1300 }} />
            </TabPane>

            {/* Shipping Average  */}
            <TabPane tab="Shipping By Date" key="3">
              <Table columns={averageShippingColumns} dataSource={averageShippingDataSource} scroll={{ x: 1300 }} />
            </TabPane>

            {/* FT Shipping Average  */}
            <TabPane tab="Fast Track Shipping by Date" key="4">
              <Table columns={averageFTShippingColumns} dataSource={averageShippingFTDataSource} scroll={{ x: 1300 }} />
            </TabPane>

          </Tabs>



          <Modal title="Import Excel" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Input type='file' accept='.xlsx' key={inputKey} onChange={(event) => setExcelFile(event.target.files[0])} />
          </Modal>

        </DefaultLayout>}
    </div>
  )
}

export default ViewShipping
