import { combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { miniReducer } from "./reducers/miniReducer";
import { loaderReducer } from "./reducers/loaderReducer";
import { maxiReducer } from "./reducers/maxiReducer";
import { rucReducer } from "./reducers/rucReducer";
import { chassisReducer } from "./reducers/chassisReducer";
import { rsaReducer } from "./reducers/rsaReducer";
import { usersReducer } from "./reducers/usersReducer";
import { doorsReducer } from "./reducers/doorsReducer";
import { electricalReducer } from "./reducers/electricalReducer";
import { shippingReducer } from "./reducers/shippingReducer";
import { kcmReducer } from "./reducers/kcmReducer";

const rootReducer = combineReducers({
    miniReducer: miniReducer,
    loaderReducer: loaderReducer,
    maxiReducer: maxiReducer,
    rucReducer: rucReducer,
    chassisReducer: chassisReducer,
    rsaReducer: rsaReducer,
    usersReducer: usersReducer,
    doorsReducer: doorsReducer,
    electricalReducer: electricalReducer,
    shippingReducer: shippingReducer,
    kcmReducer: kcmReducer,
})

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;