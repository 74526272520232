import React, { useEffect, useState } from 'react'
import { DatePicker, Button, Select } from 'antd'
import moment from 'moment';
import CalendarStrip from './CalendarStrip';

const { Option } = Select;

function Filter(props) {
    const startYear = 2020
    const dateFormat = 'ddd, MMMM Do YYYY';
    const defaultDate = moment(new Date(), dateFormat);

    const [params, setParams] = useState({
        date: props.datePlcaeHolder ? null : defaultDate,
        line: !props.isTrendLine ? "ALL" : "MINI",
        dataType: "weekly",
        weekNo: 1,
        filterBy: !props.isTrendLine ? "Date" : "Yearly",
        month: 0,
        year: defaultDate.format("YYYY"),
        lastday: defaultDate,
        firstday: defaultDate,
        model: null
    });
    const [reset, setReset] = useState(false)
    const [noOFWeeks, setNoOFWeeks] = useState(0);
    const [currentWeekNo, setCurrentWeekNo] = useState(0);

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        if (!props.isTrendLine) {
            let weekDate = getDateOfWeek(1, currentYear);
            setWeekDate(weekDate, 1);
        }
        // console.log(props);
    }, [])

    function onChange(value, type) {
        setParams({
            ...params,
            date: type == 'date' ? value : params.date,
        });
    }

    const onReset = () => {
        setParams({
            date: props.datePlcaeHolder ? null : defaultDate,
            line: !props.isTrendLine ? "ALL" : "MINI",
            weekNo: 1,
            filterBy: !props.isTrendLine ? "Date" : "Yearly",
            month: 0,
            year: currentYear,
            lastday: defaultDate,
            firstday: defaultDate,
            dataType: "weekly",
            model: null
        });
        setReset(true);
        props.onReset();
    }

    const getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(+d);
        const year = d.getFullYear();
        d.setHours(0, 0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        var yearStart = new Date(year, 0, 1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        // Return array of year and week number
        return [year, weekNo];
    }

    const weeksInYear = (year) => {
        var month = 11,
            day = 31,
            week;

        // Find week that 31 Dec is in. If is first week, reduce date until
        // get previous week.
        do {
            let d = new Date(year, month, day--);
            week = getWeekNumber(d)[1];
        } while (week == 1);

        return week;
    }

    const getCurrentWeekNo = () => {
        let currentdate = new Date();
        var oneJan = new Date(currentdate.getFullYear(), 0, 1);
        var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        var result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
        setNoOFWeeks(result);
    }

    const getDateOfWeek = (weekNo, year) => {
        var d = (1 + (weekNo - 1) * 7); // 1st of January + 7 days for each week
        return new Date(year, 0, d);
    }

    const setWeekDate = (date, weekNo) => {
        var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        var firstday = new Date(date.setDate(first));
        var lastday = new Date(date.setDate(last));
        if (first < 0)
            lastday = new Date(lastday.setMonth(firstday.getMonth() + 1))

        setParams({ ...params, firstday: firstday, lastday: lastday, weekNo: weekNo })
    }

    useEffect(() => {
        setNoOFWeeks(weeksInYear(currentYear));
        getCurrentWeekNo()
    }, [])

    const MINI_MODELS = [
        "DC6LP", "DC6", "DC7", "DC7HG", "CD10", "CD12", "CD3356SSL", "CD14", "RUC27", "DC10PT", "WC10-12",
        "CD26", "CD34", "CD38", "CD40", "CD45", "CD47", "CD4148SL", "CD4154", "CD4160", "WC4889TR", "WC3982", "WC5182",
        "CD20", "PC8", "CD3055", "CD3354CPT", "CD3360CPT", "GF8FZ", "WC20",
        "CD45HC", "CD34-HC", "CD38-HC-EMB", "CD40-HC-EMB", "CD47-HC-EMB", "CD26-HC-M", "CD4148SSL-HC", "CD4154HC", "CD4160HC", "WC26-HC", "AC48",
    ];

    return (
        <div className="d-flex pr-3 py-3 align-items-center">
            <p>{props.datePlcaeHolder}</p>

            {props.hasLine ?
                <div>
                    <p>Select Line: </p>
                    <Select value={params.line} style={{ width: 250, marginRight: 10 }}
                        onChange={(value) => setParams({ ...params, line: value })}>
                        {!props.isTrendLine && <Option value="ALL">ALL</Option>}
                        <Option value="MINI">MINI</Option>
                        <Option value="MAXI">MAXI</Option>
                        <Option value="KCM">KCM</Option>
                        <Option value="RUC">RUC</Option>
                        <Option value="Chassis">Chassis</Option>
                        <Option value="Refrigeration Sub Assembly">Refrigeration Sub Assembly</Option>
                        {props.isEfficiency != 'true' && <>
                            <Option value="Electrical">Electrical</Option>
                            <Option value="Doors">Doors</Option>
                        </>}
                        {!props.isTrendLine && <>
                            <Option value="Shipping">Shipping</Option>
                        </>}

                    </Select>
                </div>
                :
                <></>}

            {props.isTrendLine ?
                <div>
                    <p>Data Type: </p>
                    <Select value={params.dataType} style={{ width: 100, marginRight: 10 }}
                        onChange={(value) => setParams({ ...params, dataType: value })}>
                        <Option value="daily">Daily</Option>
                        <Option value="weekly">Weekly</Option>
                    </Select>
                </div>
                :
                <></>}


            <div>
                <p>Filter By: </p>
                <Select value={params.filterBy} style={{ width: 250, marginRight: 10 }}
                    onChange={(value) => setParams({ ...params, filterBy: value })}>
                    {!props.isTrendLine ? <Option value="Date">Date</Option> : <Option value="Yearly">Yearly</Option>}
                    {!props.isTrendLine ? <Option value="WeekNo">Week No.</Option> : <Option value="dateRange">Date Range</Option>}
                    <Option value="Month">Month</Option>
                    {/* {props.filterModel ?  : <></>} */}
                    {props.filterModel && <Option value="Model">Model</Option>}
                </Select>
            </div>

            {params.filterBy === "Date" ?
                <div>
                    <p>Select Date: </p>
                    <DatePicker
                        value={params.date}
                        onChange={(date) => onChange(date, 'date')}
                        format={dateFormat}
                        defaultValue={props.datePlcaeHolder ? null : defaultDate}
                        style={{ width: 300 }}
                        className="mr-2"
                        placeholder={props.datePlcaeHolder}
                    />
                </div>
                : <div className='d-flex flex-row'>
                    {params.filterBy === "WeekNo" ?
                        <div>
                            <p>Select Week No: </p>
                            <Select value={params.weekNo} style={{ width: 200, marginRight: 10 }}
                                onChange={(value) => {
                                    let weekDate = getDateOfWeek(value, currentYear);
                                    setWeekDate(weekDate, value);
                                }}>
                                {Array.from(Array(noOFWeeks).keys()).map(x => {
                                    return <Option value={`${x + 1}`} key={x + 1}>{x + 1}</Option>
                                })}
                            </Select>
                        </div>
                        : params.filterBy === "Month" ?
                            <div>
                                <p>Select Month: </p>
                                <Select value={params.month} style={{ width: 200, marginRight: 10 }}
                                    onChange={(value) => {
                                        setParams({ ...params, month: value })
                                    }}>
                                    <Option value="" disabled>--- Select Month ---</Option>
                                    {Array.from(Array(12).keys()).map(x => {
                                        let date = new Date();
                                        date.setMonth(x);
                                        date.setDate(1);
                                        return <Option value={x} key={x + 1}>{moment(date).format('MMM')}</Option>;
                                    })}
                                </Select>
                            </div> :
                            params.filterBy === "dateRange" ?
                                <div className='d-flex flex-row'>
                                    <div>
                                        <p>Select Start Date: </p>
                                        <DatePicker
                                            value={params.firstday}
                                            onChange={(date) => setParams({ ...params, firstday: date, year: date.format('YYYY') })}
                                            format={dateFormat}
                                            // defaultValue={defaultDate}
                                            style={{ width: 375 }}
                                            className="mr-2"
                                        />
                                    </div>
                                    <div>
                                        <p>Select End Date: </p>
                                        <DatePicker
                                            value={params.lastday}
                                            onChange={(date) => setParams({ ...params, lastday: date, year: date.format('YYYY') })}
                                            format={dateFormat}
                                            defaultValue={defaultDate}
                                            style={{ width: 375 }}
                                            className="mr-2"
                                        />
                                    </div>
                                </div> : <></>}
                    {params.filterBy !== "dateRange" && <div>
                        <p>Select Year: </p>
                        <Select value={params.year} style={{ width: 200, marginRight: 10 }}
                            onChange={(value) => {
                                setParams({ ...params, year: value });
                                if (+(defaultDate.format('YYYY')) === value) {
                                    getCurrentWeekNo();

                                } else {
                                    setNoOFWeeks(weeksInYear(value));

                                }
                            }}>
                            <Option value="" disabled>--- Select Year ---</Option>
                            {Array.from(Array((+(defaultDate.format('YYYY')) - startYear) + 1).keys()).map(year => {
                                return <Option value={startYear + year} key={year}>{startYear + year}</Option>;
                            })}

                        </Select>
                    </div>}

                </div>
            }

            {
                props.hasModel == true ?
                <div>
                    <p>Model: </p>
                    <Select value={params.model} style={{ width: 250, marginRight: 10 }}
                        onChange={(value) => setParams({ ...params, model: value })}>
                        {MINI_MODELS.map(x => <Option value={x} key={x}>{x}</Option>)}
                    </Select>
                </div> : <></>
            }

            <div style={{ marginTop: 30 }}>
                <Button onClick={() => {
                    props.onFilter(params);
                    setReset(false)
                }} 
                // disabled={!params.date && !props.isStriped} 
                className="mr-2">{props.isTrendLine ? 'Generate' : 'Filter'}</Button>
                <Button onClick={onReset}>Reset</Button>
            </div>

        </div>
    )
}

export default Filter
