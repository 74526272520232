import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllDoors = () => async dispatch => {

  dispatch({ type: 'DOORS_LOADED', payload: false })
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get(BASE_URL + '/api/doors')
    dispatch({ type: 'GET_ALL_DOORS', payload: response.data })
    dispatch({ type: 'DOORS_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'DOORS_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postDoors = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + '/api/doors', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Doors posted successfully')

    setTimeout(() => {
      window.location.href = '/doors'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editDoors = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/doors/" + values._id, values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Doors edited successfully')

    setTimeout(() => {
      window.location.href = '/doors'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const deleteDoors = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/doors", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Doors deleted successfully')

    setTimeout(() => {
      window.location.href = '/doors'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const importExcel = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/import-file", values)
    if (response.data.success) {
      dispatch({ type: 'LOADING', payload: false })
      message.success('File uploaded successfully')
      setTimeout(() => {
        window.location.href = '/doors'
      }, 1000);
    } else {
      dispatch({ type: 'LOADING', payload: false })
      message.error(response.data.result.message);
    }
  } catch (error) {
    console.log(error)
    message.error('There was an error')
    dispatch({ type: 'LOADING', payload: false })
  }
};