import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllRucs = () => async dispatch => {

    dispatch({ type: 'RUC_LOADED', payload: false })
    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + '/api/ruc')
        dispatch({ type: 'GET_ALL_RUCS', payload: response.data })
        dispatch({ type: 'RUC_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'RUC_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postRuc = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + '/api/ruc', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('RUC posted successfully')

        setTimeout(() => {
            window.location.href = '/ruc'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editRuc = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/ruc/" + values._id, values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('RUC edited successfully')

        setTimeout(() => {
            window.location.href = '/ruc'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const deleteRuc = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/ruc", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('RUC deleted successfully')

        setTimeout(() => {
            window.location.href = '/ruc'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const importExcel = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/import-file", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('File uploaded successfully')

        setTimeout(() => {
            window.location.href = '/ruc'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};