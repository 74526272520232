import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Select, Button, Modal, Input, Form } from 'antd'
import { FileExcelOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'
import { deleteChassis, importExcel } from "../redux/actions/chassisActions";
import moment from 'moment';
import Filter from '../components/Filter';
import { parseAsync, Parser } from 'json2csv';

const { Option } = Select;

function ViewChassis() {
    const allChassis = useSelector(state => state.chassisReducer).chassisList;
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('user'))

    const userid = JSON.parse(localStorage.getItem('user'))._id;
    const userPostedChassis = allChassis;
    const history = useHistory()

    const [deleteId, setDeleteId] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [excelFile, setExcelFile] = useState();
    const [inputKey, setInputKey] = useState(Date.now());

    const columns = [
        // {
        //     title: 'Work Order#',
        //     dataIndex: "workOrder",
        // },
        // {
        //     title: 'Run',
        //     dataIndex: "run",
        // },
        {
            title: 'Run Size',
            dataIndex: "runSize",
        },
        {
            title: 'DATE',
            dataIndex: "displayDate",
        },
        {
            title: 'Total Staff',
            dataIndex: "totalStaff",
        },
        {
            title: 'Shift hours',
            dataIndex: "shiftHours",
        },
        {
            title: 'Adjusted hours',
            dataIndex: "adjustedHours",
        },
        {
            title: 'Total Chassis',
            dataIndex: "totalChassis",
        },
        {
            title: 'Model',
            dataIndex: "model",
        },
        {
            title: 'Comment',
            dataIndex: "comment",
        },
        {
            title: 'Quarantine Tags',
            dataIndex: "quarantineTags",
        },
        {
            title: 'Problem Description',
            dataIndex: "problemDescription",
        },
        {
            title: 'Serial Plates Used Start #',
            dataIndex: "serialPlatesUsedStart",
        },
        {
            title: 'Serial Plates Used End #',
            dataIndex: "serialPlatesUsedEnd",
        },
        {
            title: 'Potential Failure Modes',
            dataIndex: "potentialFailureModes",
        },
        {
            title: 'Downtime(mins)',
            dataIndex: "downtimeMins",
        },
        {
            title: 'Actions',
            render: (text, data) => {
                return <div className="flex" key={data._id}>
                    {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "chassis").rights.some(x => x.toLowerCase() === 'edit')) &&
                        <EditOutlined onClick={() => { history.push(`/chassis/edit/${data._id}`) }} />}
                    {/* <DeleteOutlined onClick={() => onDelete(data.id)} style={{ color: '#db2727', marginLeft: 10 }} /> */}
                </div>
            },
        },
    ];

    useEffect(() => {
        if (userPostedChassis.length && !dataSource.length) {
            let postedChassis = userPostedChassis.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date); // sort by latest date
            }).map(x => {
                return {
                    ...x,
                    displayDate: moment(x.date).format('ddd, MMMM Do YYYY') ?? ''
                }
            })
            setDataSource(postedChassis);
            setFilteredDataSource(postedChassis);
        }
    }, [userPostedChassis])

    const handleOk = () => {
        let data = new FormData();
        data.append('type', "chassis");
        data.append('postedBy', JSON.parse(localStorage.getItem('user'))._id);
        data.append('uploadfile', excelFile);
        // console.log('excel', data);
        dispatch(importExcel(data));
        setIsModalVisible(false);
        setExcelFile();
        setInputKey(Date.now())
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setExcelFile();
        setInputKey(Date.now())
    };

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const onFilter = (params) => {
        var ds = [];
        switch (params.filterBy.toLowerCase()) {
            case "date":
                const filterDate = moment(params.date).format('MM/DD/YYYY');
                ds = dataSource.filter(x => isValidDate(new Date(x.date)) && filterDate == moment(x.date).format('MM/DD/YYYY'));
                break;
            case "weekno":
                let firstDate = new Date(moment(params.firstday).format('MM/DD/YYYY'))
                let lastDate = new Date(moment(params.lastday).format('MM/DD/YYYY'))
                firstDate.setFullYear(params.year);
                lastDate.setFullYear(params.year);
                ds = dataSource
                    .filter(x => isValidDate(new Date(x.date)) && firstDate.getTime() <= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime()) &&
                        lastDate.getTime() >= ((new Date(moment(x.date).format('MM/DD/YYYY'))).getTime())
                    )
                break;
            case "month":
                var date = new Date();
                date.setMonth(params.month);
                date.setFullYear(params.year);
                ds = dataSource
                    .filter(x => isValidDate(new Date(x.date)) && moment(date).format('MM/YY') === moment(x.date).format('MM/YY'))
                break;
            default:
                break;
        }

        setFilteredDataSource(ds);
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    // EXPORT FUNCTIONS START
  const [isExportModalVisible, setExportIsModalVisible] = useState(false);
  const years = [2019, 2020, 2021, 2022, 2023, 2024];
  const months = [{ value: 0, label: 'January' }, { value: 1, label: 'February' }, { value: 2, label: 'March' }, { value: 3, label: 'April' }, { value: 4, label: 'May' }, { value: 5, label: 'June' }, { value: 6, label: 'July' }, { value: 7, label: 'August' }, { value: 8, label: 'September' }, { value: 9, label: 'October' }, { value: 10, label: 'November' }, { value: 11, label: 'December' }];
  const showExportModal = () => {
      setExportIsModalVisible(true);
  };

  const handleExportOk = () => {
      exportData(formatExportCSVData(filteredDataSource));
      setExportIsModalVisible(false);
  };

  const handleExportCancel = () => {
      setExportIsModalVisible(false);
  };

  const exportData = async (filteredDataSource, year = null, month = null) => {
      try {
          let csv = await parseAsync(filteredDataSource, {});
          var filename, link;
          if (csv == null) return;
          filename = `chassis_data_${year ? year + "_" : ""}${month ? months[month].label.toLowerCase() + "_" : ""}${moment().format('MM-DD-YYYY')}.csv`;
          var blob = new Blob(["\ufeff", csv]);
          var url = URL.createObjectURL(blob);
          link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      } catch (err) {
          console.error(err);
      }
  }

  const onExportFinish = (values) => {
      let data = filteredDataSource.filter(x => {
          let date = new Date(x.date);
          let status = date.getFullYear() == values.export_year && date.getMonth() == values.export_month;
          return status;
      })
      data = formatExportCSVData(data);
      if(data.length > 0) exportData(data, values.export_year, values.export_month);
  }

  const formatExportCSVData = (data) => {
      data = data.map(x => {
          return {
            'Run Size': x.runSize,
            'DATE': x.displayDate,
            'Total Staff': x.totalStaff,
            'Shift hours': x.shiftHours,
            'Adjusted hours': x.adjustedHours,
            'Total Chassis': x.totalChassis,
            'Model': x.model,
            'Comment': x.comment,
            'Quarantine Tags': x.quarantineTags,
            'Problem Description': x.problemDescription,
            'Serial Plates Used Start #': x.serialPlatesUsedStart,
            'Serial Plates Used End #': x.serialPlatesUsedEnd,
            'Potential Failure Modes': x.potentialFailureModes,
            'Downtime(mins)': x.downtimeMins,
          }
      })
      return data;
  }

  // EXPORT FUNCTIONS END

    return (
        <div>
            <DefaultLayout>
                <div className='d-flex justify-content-between'>
                    <h3>Chassis</h3>
                    <div>
                        {user.isAdmin && <Button type="primary" className="mr-2" onClick={showModal}><FileExcelOutlined /> Import Excel</Button>}
                        {user.isAdmin && <Button type="primary" onClick={showExportModal}><FileExcelOutlined /> Export as Excel</Button>}
                    </div>
                </div>

                <Filter
                    onReset={() => setFilteredDataSource(dataSource)}
                    onFilter={onFilter}
                />

                {(user.isAdmin || user.menu.find(x => x.name.toLowerCase() == "chassis").rights.some(x => x.toLowerCase() === 'write')) &&
                    <div className="d-flex justify-content-end">
                        <Link to='/chassis/post' className='addBtn'>Add Chassis</Link>
                    </div>}


                <Table columns={columns} dataSource={filteredDataSource} scroll={{ x: 1300 }} />

                <Modal title="Import Excel" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Input type='file' accept='.xlsx' key={inputKey} onChange={(event) => setExcelFile(event.target.files[0])} />
                </Modal>

                <Modal title="Export Excel" visible={isExportModalVisible} 
                okText="Download All Data"
                onOk={handleExportOk} onCancel={handleExportCancel}>
                <Form name="export-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{  }}
                    onFinish={onExportFinish}
                    autoComplete="off">
                    <Form.Item label="Select Year" name="export_year" rules={[{ required: true, message: 'Select Year' }]}>
                        <Select placeholder="Select Year">
                            {
                                years.map(x => <Option key={x} value={x}>{x}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Select Month" name="export_month" rules={[{ required: true, message: 'Select Month' }]}>
                        <Select placeholder="Select Month">
                            {
                                months.map(x => <Option key={x.value} value={x.value}>{x.label}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Download Monthly Data
                        </Button>
                    </Form.Item>
                </Form>
        </Modal>
            </DefaultLayout>
        </div>
    )
}

export default ViewChassis
