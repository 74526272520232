import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllElectrical = () => async dispatch => {

  dispatch({ type: 'ELECTRICAL_LOADED', payload: false })
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get(BASE_URL + '/api/electrical')
    dispatch({ type: 'GET_ALL_ELECTRICAL', payload: response.data })
    dispatch({ type: 'ELECTRICAL_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'ELECTRICAL_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postElectrical = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + '/api/electrical', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Electrical posted successfully')

    setTimeout(() => {
      window.location.href = '/electrical'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editElectrical = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/electrical/" + values._id, values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Electrical edited successfully')

    setTimeout(() => {
      window.location.href = '/electrical'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const deleteElectrical = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/electrical", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Electrical deleted successfully')

    setTimeout(() => {
      window.location.href = '/electrical'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const importExcel = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/import-file", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('File uploaded successfully')

    setTimeout(() => {
      window.location.href = '/electrical'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};