import ViewConstant from "./ViewConstant"

function ViewCycleTime() {
  
  return <>
    <ViewConstant isCycleTimes/>
  </>

}

export default ViewCycleTime
