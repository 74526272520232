import { Table } from "antd"
import DefaultLayout from "../components/DefaultLayout"
import ViewConstant from "./ViewConstant"

const columns = [
  {
    title: 'Name',
    dataIndex: "name",
  },
  {
    title: 'Formula',
    dataIndex: "formula",
  },
];

const dataSource = [
  {
    name: "Total Units Production",
    formula: "Units total for the area",
  },
  {
    name: "Actual MAN HRS: ",
    formula: "Shift Hrs X Headcount"
  },
  {
    name: "M/HRS. PER Unit:",
    formula: "Man Hours / Units produced"
  },
  {
    name: "Theoretical Production (Mini / Maxi / RUC): ",
    formula: "Shift Hrs X 60 X60 / (Cycle time+60)"
  },
  {
    name: "Theoretical Production Chassis: ",
    formula: "Shift Hrs X 60 60 / Cycle time"
  },
  {
    name: "Theoretical Production Referigeration: ",
    formula: "Shift Hrs X 60 60 / Cycle time"
  },
  {
    name: "Theoretical Manhours per unit: ",
    formula: "Proposed Man Hrs X shift hrs / Theoretical production"
  },
  {
    name: "Efficiency",
    formula: "(Total Production X Proposed Crewing) / (Total Theoretical Production X Actual Total Staff)"
  }
]

function ViewFormulas() {
  
  return <>
    <DefaultLayout>

      <div className='d-flex justify-content-between'>
        <h3>Formulas</h3>
      </div>

      <Table columns={columns} dataSource={dataSource} scroll={{ x: 1300 }} />

    </DefaultLayout>
  </>

}

export default ViewFormulas
