import React, { useState, useEffect } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, DatePicker, TimePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { postFTShipping, editFTShipping } from '../redux/actions/shippingAction'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { TabPane } = Tabs;
const { TextArea } = Input;

function PostFtShipping({ match }) {
  const formRef = React.createRef();

  const defaultDate = moment(new Date(), 'ddd, MMMM Do YYYY');
  const defaultTime = moment('9:00 AM', 'hh:mm A');

  const { ftShippingList } = useSelector(state => state.shippingReducer)
  const shipping = match.params?.id ? ftShippingList.find(shipping => shipping._id == match.params.id) : null;

  const [activeTab, setActiveTab] = useState("0")
  const dispatch = useDispatch()
  const history = useHistory()

  function onFinalFormFinish(values) {
    const finalObj = { ...values, time: values.time.format('hh:mm A'), orderTime: values.orderTime.format('hh:mm A') };
    dispatch(shipping ? editFTShipping({ ...finalObj, _id: shipping._id }) : postFTShipping(finalObj));
  }

  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (user && !user.isAdmin) {
      const menu = user.menu.find(x => x.name.toLowerCase() == 'shipping');
      const rights = menu.rights ?? [];

      if (match.path.includes('edit') && !rights.some(x => x.toLowerCase() == 'edit')) {
        history.push('/');
      }

      if (match.path.includes('post') && !rights.some(x => x.toLowerCase() == 'write')) {
        history.push('/');
      }
    }
  }, [user])

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);

    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
    return dDisplay + hDisplay;
  }

  const onDateChange = () => {
    const combineShippingDate = moment((formRef.current.getFieldValue('date').format('MM/DD/YYYY') + ' ' + formRef.current.getFieldValue('time').format('hh:mm A')), 'MM/DD/YYYY hh:mm A');
    const combineOrderDate = moment((formRef.current.getFieldValue('orderDate').format('MM/DD/YYYY') + ' ' + formRef.current.getFieldValue('orderTime').format('hh:mm A')), 'MM/DD/YYYY hh:mm A');

    formRef.current.setFieldsValue({
      tat: secondsToDhms(combineShippingDate.diff(combineOrderDate, 'seconds'))
    })
  }

  return (
    <div>
      <DefaultLayout>
        <Tabs defaultActiveKey='0' activeKey={activeTab}>
          <TabPane tab={`${shipping ? 'Edit' : 'Add'} Fast Track Shipping`} key='0'>
            <Form
              onFinish={onFinalFormFinish} o
              nReset={() => { history.push('/') }}
              initialValues={shipping ? {
                ...shipping,
                date: moment(new Date(shipping.date), 'ddd, MMMM Do YYYY'),
                orderDate: moment(new Date(shipping.orderDate), 'ddd, MMMM Do YYYY'),
                time: moment(shipping.time, 'hh:mm A'),
                orderTime: moment(shipping.orderTime, 'hh:mm A'),
              } : null}
              validateMessages={{ required: '${label} is required!' }}
              ref={formRef}
            >
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='date' rules={[{ required: true }]} label='Shipping Date' initialValue={defaultDate}>
                    <DatePicker
                      format='ddd, MMMM Do YYYY'
                      style={{ width: '100%' }}
                      defaultValue={defaultDate}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='time' rules={[{ required: true }]} label='Shipping Time' initialValue={defaultTime}>
                    <TimePicker
                      style={{ width: '100%' }}
                      showSecond={false}
                      format={'hh:mm A'}
                      minuteStep={15}
                      defaultValue={defaultTime}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='po' rules={[{ required: true }]} label='PO#' >
                    <Input type='number' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='qtyShippied' rules={[{ required: true }]} label='QTY Shipped'>
                    <Input type='number' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='extendedPrice' rules={[{ required: true }]} label='Extended price of shipment'>
                    <Input type='number' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='comment' rules={[{ required: false }]} label='Comment'>
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='orderDate' rules={[{ required: true }]} label='Order Date' initialValue={defaultDate}>
                    <DatePicker
                      format='ddd, MMMM Do YYYY'
                      style={{ width: '100%' }}
                      defaultValue={defaultDate}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='orderTime' rules={[{ required: true }]} label='Order Time' initialValue={defaultTime}>
                    <TimePicker
                      style={{ width: '100%' }}
                      showSecond={false}
                      format={'hh:mm A'}
                      minuteStep={15}
                      defaultValue={defaultTime}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='tat' rules={[{ required: true }]} label='Turn Around Time'>
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Button htmlType='submit'>Submit</Button>
              <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
            </Form>
          </TabPane>

        </Tabs>
      </DefaultLayout>
    </div>
  )
}

export default PostFtShipping
