import axios from "axios";
import { message } from 'antd'
import { BASE_URL } from "../../constant/api";

export const registerUser = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })

    try {
        await axios.post(BASE_URL + '/api/users/register', values)
        message.success('User Registered Successessfully')
        setTimeout(() => {
            window.location.href = '/users'
        }, 1000);
        dispatch({ type: 'LOADING', payload: false })

    } catch (error) {
        message.error("Something went wrong, please try again later.")
        dispatch({ type: 'LOADING', payload: false })

    }

}


export const loginUser = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })

    try {
        const ip_res = await axios.get('https://geolocation-db.com/json/');
        const ip_address = ip_res.data.IPv4;
        const user = await axios.post(BASE_URL + '/api/users/login', {...values, ip_address})
        message.success('Login successful')
        localStorage.setItem('user', JSON.stringify(user.data))
        setTimeout(() => {
            window.location.href = '/'
        }, 1000);
        dispatch({ type: 'LOADING', payload: false })

    } catch (error) {
        console.log(error)
        message.error(error?.response?.data?.message ?? "Invalid credentials.")
        dispatch({ type: 'LOADING', payload: false })

    }

}

export const updateUser = (values, isChangesPw) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })

    try {
        const user = await axios.post(BASE_URL + '/api/users/update', values)
        message.success('Update Successfull')
        if (!isChangesPw)
            setTimeout(() => {
                window.location.href = '/users'
            }, 1000);
        dispatch({ type: 'LOADING', payload: false })

    } catch (error) {
        message.error("Something went wrong, please try again later.")
        dispatch({ type: 'LOADING', payload: false })

    }

}

export const getAllUsers = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + "/api/users/getallusers");
        dispatch({ type: 'GET_ALL_USERS', payload: response.data });
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false });
    }
};

export const getConstants = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + "/api/constant");
        dispatch({ type: 'GET_CONSTANTS', payload: response.data });
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false });
    }
};

export const updateConstant = (params) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/constant/update", params);
        dispatch({ type: 'GET_CONSTANTS', payload: response.data });
        dispatch({ type: 'LOADING', payload: false })
        message.success('Constant Updateds Successfull')
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false });
    }
};

export const updateLog = (params) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const ip_res = await axios.get('https://geolocation-db.com/json/');
        const ip_address = ip_res.data.IPv4;
        const response = await axios.post(BASE_URL + "/api/users/update-log", {...params, ipAddress: ip_address});
        dispatch({ type: 'LOADING', payload: false });
        // perform a reload if the update was for logout
        if(params.logout){
            localStorage.removeItem('user')
            window.location.reload();
        }
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false });
    }
};

export const getAllUserLogs = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + "/api/users/get-user-logs");
        console.log(response.data);
        dispatch({ type: 'GET_USER_LOGS', payload: response.data });
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false });
    }
};