import './App.css';
import 'antd/dist/antd.css';
// import SiteInfo from './pages/SiteInfo';

import { useEffect } from "react";
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";
import { useDispatch, useSelector } from "react-redux";

import Login from './pages/Login';
import Register from './pages/Register';

import Dashboard from './pages/Dashboard';
import ViewMini from './pages/ViewMini';
import ViewMaxi from './pages/ViewMaxi';
import ViewKcm from './pages/ViewKcm';
import ViewRuc from './pages/ViewRuc';
import ViewChassis from './pages/ViewChassis';
import ViewRsa from './pages/ViewRsa';

import PostMaxi from './pages/PostMaxi';
import PostKcm from './pages/PostKcm';
import PostMini from './pages/PostMini';
import PostRuc from './pages/PostRuc';
import PostChassis from './pages/PostChassis';
import PostRsa from './pages/PostRsa';

import { getAllMaxi } from './redux/actions/maxiActions';
import { getAllKcm } from './redux/actions/kcmActions';
import { getAllMinis } from "./redux/actions/miniActions.js";
import { getAllRucs } from './redux/actions/rucActions';
import { getAllChassis } from './redux/actions/chassisActions';
import { getAllDoors } from './redux/actions/doorsActions';
import { getAllElectrical } from './redux/actions/electricalActions';
import { getAllRsa } from './redux/actions/rsaActions';
import { getConstants, getAllUsers, updateLog, getAllUserLogs } from './redux/actions/userActions';
import { getAllShipping, getAllFTShipping } from './redux/actions/shippingAction';
import ViewConstant from './pages/ViewConstant';
import ViewDoors from './pages/ViewDoors';
import ViewElectrical from './pages/ViewElectrical';
import PostDoors from './pages/PostDoors';
import PostElectrical from './pages/PostElectrical';
import ViewShipping from './pages/ViewShipping';
import PostShipping from './pages/PostShipping';
import ViewUser from './pages/ViewUser';
import PostUser from './pages/PostUser';
import PostFtShipping from './pages/PostFTShipping';
import ViewCycleTime from './pages/ViewCycleTimes';
import ViewFormulas from './pages/ViewFormulas';
import ViewUserLogs from './pages/ViewUserLogs';

function App() {
  const { loader } = useSelector(state => state.loaderReducer)
  const allConstants = useSelector(state => state.usersReducer).constants;
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    // check if the user is logged in or not 
    // if(user){
    //   dispatch(updateLog({
    //     userId: user._id,
    //     name: user.username,
    //     email: user.email,
    //     logout: false
    //   }))
    // }

    dispatch(getAllKcm());
    dispatch(getAllMinis());
    dispatch(getAllMaxi());
    dispatch(getAllRucs());
    dispatch(getAllChassis());
    dispatch(getAllRsa());
    dispatch(getConstants());
    dispatch(getAllDoors());
    dispatch(getAllElectrical());
    dispatch(getAllShipping());
    dispatch(getAllFTShipping());
    if (user && user.isAdmin)
      dispatch(getAllUsers());
      // dispatch(getAllUserLogs());
  }, []);

  useEffect(() => {
    if (allConstants && allConstants.length) {
      const deployedDate = localStorage.getItem('deployedDate');
      const newDeployedDate = allConstants.find(x => x.text == "Deployment Date").value[0];

      if (deployedDate) {
        if (deployedDate !== newDeployedDate) {
          localStorage.removeItem('user')
          localStorage.setItem('deployedDate', newDeployedDate)
          window.location.reload();
        }

      } else {
        localStorage.removeItem('user')
        localStorage.setItem('deployedDate', newDeployedDate)
        window.location.reload();
      }

    }
  }, [allConstants]);

  return (
    <div className="App">
      {loader && (<div className="sweet-loading text-center"><RingLoader color={'#157dec'} /></div>)}


      <BrowserRouter>
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />

        {/* Display Routes */}
        <ProtectedRoute path="/" exact component={Dashboard} />
        <ProtectedRoute path="/dashboard" exact component={Dashboard} />
        <ProtectedRoute path="/mini" exact component={ViewMini} />
        <ProtectedRoute path="/maxi" exact component={ViewMaxi} />
        <ProtectedRoute path="/kcm" exact component={ViewKcm} />
        <ProtectedRoute path="/ruc" exact component={ViewRuc} />
        <ProtectedRoute path="/chassis" exact component={ViewChassis} />
        <ProtectedRoute path="/rsa" exact component={ViewRsa} />
        <ProtectedRoute path="/constants" exact component={ViewConstant} />
        <ProtectedRoute path="/cycle-times" exact component={ViewCycleTime} />
        <ProtectedRoute path="/formulas" exact component={ViewFormulas} />
        <ProtectedRoute path="/doors" exact component={ViewDoors} />
        <ProtectedRoute path="/electrical" exact component={ViewElectrical} />
        <ProtectedRoute path="/shipping" exact component={ViewShipping} />
        <ProtectedRoute path="/users" exact component={ViewUser} />
        {/* <ProtectedRoute path="/logs" exact component={ViewUserLogs} /> */}

        {/* Post Routes */}
        <ProtectedRoute path="/mini/post" exact component={PostMini} />
        <ProtectedRoute path="/maxi/post" exact component={PostMaxi} />
        <ProtectedRoute path="/kcm/post" exact component={PostKcm} />
        <ProtectedRoute path="/ruc/post" exact component={PostRuc} />
        <ProtectedRoute path="/chassis/post" exact component={PostChassis} />
        <ProtectedRoute path="/doors/post" exact component={PostDoors} />
        <ProtectedRoute path="/electrical/post" exact component={PostElectrical} />
        <ProtectedRoute path="/rsa/post" exact component={PostRsa} />
        <ProtectedRoute path="/shipping/post" exact component={PostShipping} />
        <ProtectedRoute path="/shipping-ft/post" exact component={PostFtShipping} />
        <ProtectedRoute path="/user/post" exact component={PostUser} />

        {/* Edit Routes */}
        <ProtectedRoute path="/mini/edit/:id" exact component={PostMini} />
        <ProtectedRoute path="/maxi/edit/:id" exact component={PostMaxi} />
        <ProtectedRoute path="/kcm/edit/:id" exact component={PostKcm} />
        <ProtectedRoute path="/ruc/edit/:id" exact component={PostRuc} />
        <ProtectedRoute path="/chassis/edit/:id" exact component={PostChassis} />
        <ProtectedRoute path="/rsa/edit/:id" exact component={PostRsa} />
        <ProtectedRoute path="/user/edit/:id" exact component={PostUser} />
        <ProtectedRoute path="/doors/edit/:id" exact component={PostDoors} />
        <ProtectedRoute path="/electrical/edit/:id" exact component={PostElectrical} />
        <ProtectedRoute path="/shipping/edit/:id" exact component={PostShipping} />
        <ProtectedRoute path="/shipping-ft/edit/:id" exact component={PostFtShipping} />

      </BrowserRouter>
    </div>
  );
}

export default App;

export function ProtectedRoute(props) {
  const user = localStorage.getItem('user')

  if (!user) {
    return <Redirect to='/login/'> </Redirect>
  }
  else {
    return <Route {...props} />
  }
}
