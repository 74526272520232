import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Select, Input } from 'antd'
import { SaveFilled } from '@ant-design/icons';
import { updateConstant } from "../redux/actions/userActions";
import { useHistory } from 'react-router-dom';

const { Option } = Select;

function ViewConstant(props) {
  const allConstant = useSelector(state => state.usersReducer).constants;
  const dispatch = useDispatch()


  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  // months data state variables
  const [cycleTimeMiniMonths, setCyleTimeMiniMonths] = useState([]);
  const [cycleTimeMaxiMonths, setCyleTimeMaxiMonths] = useState([]);
  const [cycleTimeRucMonths, setCyleTimeRucMonths] = useState([]);
  const [cycleTimeChassisMonths, setCyleTimeChassisMonths] = useState([]);
  const [cycleTimeKcmMonths, setCyleTimeKcmMonths] = useState([]);
  // select object state varaibles
  const initialObjectValue = {year: 2020, month: "Jan", value: 0, isSelected: false};
  const [miniCycleTimeObject, setMiniCycleTimeObject] = useState(initialObjectValue);
  const [maxiCycleTimeObject, setMaxiCycleTimeObject] = useState(initialObjectValue);
  const [rucCycleTimeObject, setRucCycleTimeObject] = useState(initialObjectValue);
  const [chassisCycleTimeObject, setChassisCycleTimeObject] = useState(initialObjectValue);
  const [kcmCycleTimeObject, setKcmCycleTimeObject] = useState(initialObjectValue);

  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'))

  let cycleTimeValues = [120, 280, 300, 360];
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

  useEffect(() => {
    if (user && !user.isAdmin) {
      history.push('/');
    }
  }, [user])

  const columns = [
    {
      title: 'Constant',
      dataIndex: "text",
    },
    {
      title: 'Value',
      render: (text, data) => {
        return data.text.includes("Average Days") ? <Select
          key={data["_id"]} className="w-50"
          defaultValue={data.value.find(x => x.isSelected).value}
          onChange={(value) => onChange(value, data.text)}>
          {data.value.map(x => {
            return <Option value={x.value} key={x.value}>{x.value}</Option>
          })}
        </Select> : 
        data.text.includes("Cycle Time") ? <>
          <Select
            key={"year"+data["_id"]} className="w-25"
            defaultValue={
              data.text.includes("Cycle Time mini") ? miniCycleTimeObject.year : 
              data.text.includes("Cycle Time maxi") ? maxiCycleTimeObject.year :
              data.text.includes("Cycle Time chassis") ? chassisCycleTimeObject.year :
              data.text.includes("Cycle Time kcm") ? kcmCycleTimeObject.year :
              rucCycleTimeObject.year
            }
            onChange={(value) => { 
              updateYearsDataOnChange(data.text, value);

            }}>
            {
              years.map(x => {
                return <Option value={x} key={x}>{x}</Option>
              })
            }
          </Select>
          <Select
            key={"month"+data["_id"]} className="w-25"
            defaultValue={
              data.text.includes("Cycle Time mini") ? miniCycleTimeObject.month : 
              data.text.includes("Cycle Time maxi") ? maxiCycleTimeObject.month :
              data.text.includes("Cycle Time chassis") ? chassisCycleTimeObject.month :
              data.text.includes("Cycle Time kcm") ? kcmCycleTimeObject.month :
              rucCycleTimeObject.month
            }
            onChange={(value) => { 
              updateMonthsDataOnChange(data, value);
            }}>
            {
              months.map(x => {
                return <Option value={x} key={x}>{x}</Option>
              })
            }
          </Select>
          <Select
            id={"value"+data["_id"]}
            className="w-25"
            value={
              data.text.includes("Cycle Time mini") ? miniCycleTimeObject.value : 
              data.text.includes("Cycle Time maxi") ? maxiCycleTimeObject.value :
              data.text.includes("Cycle Time chassis") ? chassisCycleTimeObject.value :
              data.text.includes("Cycle Time kcm") ? kcmCycleTimeObject.value :
              rucCycleTimeObject.value
            }
            onChange={(value) => onCycleTimeChange(value, data.text)}>
            {cycleTimeValues.map(x => {
              return <Option value={x} key={x}>{x}</Option>
            })}
          </Select>
        </> : 
        data.text.toLowerCase() == "menu" ? <p>{data.value.map(x => x.name).join(', ')}</p> : 
        <div>
          <Input className='w-50 mr-2' defaultValue={data.value} onChange={(event) => {
            let values = dataSource.map(x => {
              if (x.text === data.text) {
                x.value = [event.target.value];
              }
              return x;
            })
            setFilteredDataSource(values);
          }} />
          <SaveFilled onClick={(value) => onChange(value, data.text)} />
        </div>
      },
    },
  ];

  // method to update the months data on select change
  function updateMonthsDataOnChange(data, value){
    if(data.text === 'Cycle Time mini'){
      let obj = {
        ...miniCycleTimeObject,
        month: value,
      };
      let updated_obj = updateObjectForValue(cycleTimeMiniMonths, obj);
      setMiniCycleTimeObject(updated_obj);
      let updated = updateCycleTimeValue(cycleTimeMiniMonths, updated_obj)
      setCyleTimeMiniMonths(updated);
    }
    else if(data.text === 'Cycle Time maxi'){
      let obj = {
        ...maxiCycleTimeObject,
        month: value,
      };
      let updated_obj = updateObjectForValue(cycleTimeMaxiMonths, obj);
      setMaxiCycleTimeObject(updated_obj);
      let updated = updateCycleTimeValue(cycleTimeMaxiMonths, updated_obj);
      setCyleTimeMaxiMonths(updated);
    }
    else if(data.text === 'Cycle Time ruc'){
      let obj = {
        ...rucCycleTimeObject,
        month: value,
      };
      let updated_obj = updateObjectForValue(cycleTimeRucMonths, obj);
      setRucCycleTimeObject(updated_obj);
      let updated = updateCycleTimeValue(cycleTimeRucMonths, updated_obj);
      setCyleTimeRucMonths(updated);
    }
    else if(data.text === 'Cycle Time chassis'){
      let obj = {
        ...chassisCycleTimeObject,
        month: value,
      };
      let updated_obj = updateObjectForValue(cycleTimeChassisMonths, obj);
      setChassisCycleTimeObject(updated_obj);
      let updated = updateCycleTimeValue(cycleTimeChassisMonths, updated_obj);
      setCyleTimeChassisMonths(updated);
    }
    else if(data.text === 'Cycle Time kcm'){
      let obj = {
        ...kcmCycleTimeObject,
        month: value,
      };
      let updated_obj = updateObjectForValue(cycleTimeKcmMonths, obj);
      setKcmCycleTimeObject(updated_obj);
      let updated = updateCycleTimeValue(cycleTimeKcmMonths, updated_obj);
      setCyleTimeKcmMonths(updated);
    }
  }

  // method to check if the object is having a cycle time value and
  // if yes then update the cycle time value
  function updateObjectForValue(array, object){
    let obj = array.find(x => x.year === object.year && x.month === object.month);
    if(obj){
      object.value = obj.value;
    }
    return object;
  }

  // method to update the year data on select change
  function updateYearsDataOnChange(type, value){
    if(type === 'Cycle Time mini'){
      setMiniCycleTimeObject({
        ...miniCycleTimeObject,
        year: value,
      });
    }
    else if(type === 'Cycle Time maxi'){
      setMaxiCycleTimeObject({
        ...maxiCycleTimeObject,
        year: value,
      });
    }
    else if(type === 'Cycle Time ruc'){
      setRucCycleTimeObject({
        ...rucCycleTimeObject,
        year: value,
      });
    }
    else if(type === 'Cycle Time chassis'){
      setRucCycleTimeObject({
        ...chassisCycleTimeObject,
        year: value,
      });
    }
    else if(type === 'Cycle Time kcm'){
      setKcmCycleTimeObject({
        ...kcmCycleTimeObject,
        year: value,
      });
    }
  }

  // method to update the cycle time value on select change
  function updateCycleTimeValue(array, object){
    let data = array;
    if(object.year && object.month){
      let updated = resetMonthsArray(array, object.year, object.month);
      data = updated;
    }
    return data;
  }

  function resetMonthsArray(data, year, month){
    const updateMonths = data.map(x => {
      return {
        ...x,
        isSelected: false
      }
    });
    let obj = updateMonths.find(x => x.year === year && x.month === month);
    if(obj) obj.isSelected = true;
    return updateMonths;
  }

  const onChange = (value, text) => {
    let params = filteredDataSource.find(x => x.text == text);
    // text.toLowerCase().includes("cycle time") || text.includes("Average Days")
    if (text.includes("Average Days")) {
      let values = params.value.map(x => {
        return { ...x, isSelected: x.value === value }
      });
      params.value = values;
    }
    dispatch(updateConstant(params));
  }

  // method which triggers when the cycle TIME is selected
  const onCycleTimeChange = (value, text) => {
    let params = filteredDataSource.find(x => x.text == text);
    let obj = {};
    if(text.includes("Cycle Time mini")){
      obj = {
        ...miniCycleTimeObject,
        value: value,
      };
      setMiniCycleTimeObject(obj);
      updateMonthsArray(text, obj);
      params.value = cycleTimeMiniMonths;
      dispatch(updateConstant(params));
    }
    else if(text.includes("Cycle Time maxi")){
      obj = {
        ...maxiCycleTimeObject,
        value: value,
      };
      setMaxiCycleTimeObject(obj);
      updateMonthsArray(text, obj);
      params.value = cycleTimeMaxiMonths;
      dispatch(updateConstant(params));
    }
    else if(text.includes("Cycle Time ruc")){
      obj = {
        ...rucCycleTimeObject,
        value: value,
      };
      setRucCycleTimeObject(obj);
      updateMonthsArray(text, obj);
      params.value = cycleTimeRucMonths;
      dispatch(updateConstant(params));
    }
    else if(text.includes("Cycle Time chassis")){
      obj = {
        ...chassisCycleTimeObject,
        value: value,
      };
      setChassisCycleTimeObject(obj);
      updateMonthsArray(text, obj);
      params.value = cycleTimeChassisMonths;
      dispatch(updateConstant(params));
    }
    else if(text.includes("Cycle Time kcm")){
      obj = {
        ...kcmCycleTimeObject,
        value: value,
      };
      setKcmCycleTimeObject(obj);
      updateMonthsArray(text, obj);
      params.value = cycleTimeKcmMonths;
      dispatch(updateConstant(params));
    }
  }

  // method to update the months array
  function updateMonthsArray(text, obj){
    if(text.includes("Cycle Time mini")){ 
      setCyleTimeMiniMonths(replaceOrAddMonthsArray(cycleTimeMiniMonths, obj));
    }
    if(text.includes("Cycle Time maxi")){  
      setCyleTimeMaxiMonths(replaceOrAddMonthsArray(cycleTimeMaxiMonths, obj));
    }
    if(text.includes("Cycle Time ruc")){
      setCyleTimeRucMonths(replaceOrAddMonthsArray(cycleTimeRucMonths, obj));
    }
    if(text.includes("Cycle Time chassis")){
      setCyleTimeChassisMonths(replaceOrAddMonthsArray(cycleTimeChassisMonths, obj));
    }
    if(text.includes("Cycle Time kcm")){
      setCyleTimeKcmMonths(replaceOrAddMonthsArray(cycleTimeKcmMonths, obj));
    }
  }

  // method to check the array for the object and add it to the array if it is not present
  // if the object is present then replace the value of the object
  function replaceOrAddMonthsArray(array, obj){
    let index = array.findIndex(x => x.year === obj.year && x.month === obj.month);
    if(index === -1){
      array.push(obj);
    }
    else{
      array[index] = obj;
    }
    return array;
  }

  useEffect(() => {
    if (allConstant.length && !dataSource.length) {
      let postedRuc = allConstant.map(x => {
        return x.text === "Cycle Time" ? { ...x, selected: x.value.find(x => x.isSelected).value } : x
      });
      setDataSource(postedRuc.filter(x => !x.hide));
      setFilteredDataSource(postedRuc.filter(x => !x.hide));
      // console.log(allConstant.find(x => x.text === "Cycle Time mini"));
      setCyleTimeMiniMonths(allConstant.find(x => x.text === "Cycle Time mini").value);
      setCyleTimeMaxiMonths(allConstant.find(x => x.text === "Cycle Time maxi").value);
      setCyleTimeRucMonths(allConstant.find(x => x.text === "Cycle Time ruc").value);
      setCyleTimeChassisMonths(allConstant.find(x => x.text === "Cycle Time chassis").value);
      setCyleTimeKcmMonths(allConstant.find(x => x.text === "Cycle Time kcm").value);
      // set the objects
      setMiniCycleTimeObject(getDefaultTimeValue(allConstant.find(x => x.text === "Cycle Time mini").value));
      setMaxiCycleTimeObject(getDefaultTimeValue(allConstant.find(x => x.text === "Cycle Time maxi").value));
      setRucCycleTimeObject(getDefaultTimeValue(allConstant.find(x => x.text === "Cycle Time ruc").value));
      setChassisCycleTimeObject(getDefaultTimeValue(allConstant.find(x => x.text === "Cycle Time chassis").value));
      setKcmCycleTimeObject(getDefaultTimeValue(allConstant.find(x => x.text === "Cycle Time kcm").value));
    }
  }, [allConstant])

  const getDefaultTimeValue = (data) => {
    let value = null;
    let d = new Date();
    let year = d.getFullYear();
    let month = months[d.getMonth()];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if(element.year === year && element.month === month){
        value = element;
      }
    }
    return value ? value : data[0];
  }

  useEffect(() => {
    console.log(miniCycleTimeObject);
  }, [miniCycleTimeObject])

  useEffect(() => {
    console.log(maxiCycleTimeObject);
  }, [maxiCycleTimeObject])

  useEffect(() => {
    console.log(rucCycleTimeObject);
  }, [rucCycleTimeObject])

  useEffect(() => {
    console.log(chassisCycleTimeObject);
  }, [chassisCycleTimeObject])

  return (
    <div>
      <DefaultLayout>
        <h3>{
          props.isCycleTimes ? "Cycle Times" : 
          props.isFormulas ? "Formulas" : "Constants"
        }</h3>
        <Table columns={columns} dataSource={
          props.isCycleTimes ? filteredDataSource.filter(x => x.text.includes("Cycle Time"))
          : filteredDataSource.filter(x => !x.text.includes("Cycle Time"))
        } scroll={{ x: 1300 }} />
      </DefaultLayout>
    </div>
  )
}

export default ViewConstant
