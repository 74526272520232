import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllMaxi = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    dispatch({ type: 'MAXIS_LOADED', payload: false })
    try {
        const response = await axios.get(BASE_URL + '/api/maxi')
        dispatch({ type: 'GET_ALL_MAXIS', payload: response.data })
        dispatch({ type: 'MAXIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'MAXIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postMaxi = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + '/api/maxi', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Maxi posted successfully')

        setTimeout(() => {
            window.location.href = '/maxi'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editMaxi = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/maxi/" + values._id, values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Maxi edited successfully')

        setTimeout(() => {
            window.location.href = '/maxi'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const deleteMaxi = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/maxi", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Maxi deleted successfully')

        setTimeout(() => {
            window.location.href = '/maxi'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const importExcel = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/import-file", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('File uploaded successfully')

        setTimeout(() => {
            window.location.href = '/maxi'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};