import React from 'react'
import { Row, Col, Form, Input, Button } from 'antd'
import { loginUser } from '../redux/actions/userActions'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';

function Login() {
    const dispatch = useDispatch()
    function login(values) {
        dispatch(loginUser(values))
    }

    return (
        <div className="login">
            <Row justify='center' >
                <Col lg={10} sm={24} className="bs p-5">
                    <Row justify='center'>
                        <div className='companyName'>Du North Brampton</div>
                    </Row>

                    <h1>Login</h1>
                    <hr />
                    <Form layout="vertical" onFinish={login}>
                        <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>

                        <Button htmlType='submit'>Login</Button><br />
                        {/* <Link to='/register'>Not registered? Click here to register.</Link> */}
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default Login
