const initialState = {
    users: [],
    constants: [],
    logs: []
}

export const usersReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_USERS': return {
            ...state,
            users: action.payload

        }
        case 'GET_CONSTANTS': return {
            ...state,
            constants: action.payload

        }
        case 'GET_USER_LOGS': return {
            ...state,
            logs: action.payload

        }
        default: return state

    }

}