const initialState = {
    maxiList: [],
    loaded: false
}

export const maxiReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_MAXIS': return {
            ...state,
            maxiList: action.payload
        }
        case 'MAXIS_LOADED': return {
            ...state,
            loaded: action.payload
        }
        default: return state
    }
}