import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllShipping = () => async dispatch => {

  dispatch({ type: 'SHIPPING_LOADED', payload: false })
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get(BASE_URL + '/api/shipping')
    dispatch({ type: 'GET_ALL_SHIPPING', payload: response.data })
    dispatch({ type: 'SHIPPING_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'SHIPPING_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postShipping = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + '/api/shipping', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Shipping posted successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editShipping = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/shipping/" + values._id, values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('shipping edited successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const deleteShipping = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/shipping", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('shipping deleted successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const getAllFTShipping = () => async dispatch => {

  dispatch({ type: 'SHIPPING_LOADED', payload: false })
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get(BASE_URL + '/api/ft-shipping')
    dispatch({ type: 'GET_ALL_FT_SHIPPING', payload: response.data })
    dispatch({ type: 'SHIPPING_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'SHIPPING_LOADED', payload: true })
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postFTShipping = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + '/api/ft-shipping', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Shipping posted successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editFTShipping = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/ft-shipping/" + values._id, values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('shipping edited successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const deleteFTShipping = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post(BASE_URL + "/api/ft-shipping", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('shipping deleted successfully')

    setTimeout(() => {
      window.location.href = '/shipping'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};