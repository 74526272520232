import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Button, Form, Modal, Select, Table, Tabs } from 'antd'
import moment from 'moment-timezone';
import Filter from '../components/Filter';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { getAllUsers } from '../redux/actions/userActions';
import ViewShipping from './ViewShipping'
import { parseAsync, Parser } from 'json2csv';
import { FileExcelOutlined } from '@ant-design/icons';
import ViewProducedGraph from './ViewProducedGraph';
const { Option } = Select;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            lineWidth: 20
        },
        title: {
            display: true,
            text: 'Total Produciton Units',
        },
    },
};

export const options2 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            lineWidth: 20
        },
        title: {
            display: true,
            text: 'Manhours/Unit',
        },
    },
};

export const options3 = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            lineWidth: 20
        },
        title: {
            display: true,
            text: 'Total Efficiency',
        },
    },
};

const { TabPane } = Tabs;

function Dashboard() {
    const miniData = useSelector(state => state.miniReducer);
    const maxiData = useSelector(state => state.maxiReducer);
    const chassisData = useSelector(state => state.chassisReducer);
    const rsaData = useSelector(state => state.rsaReducer);
    const rucData = useSelector(state => state.rucReducer);
    const electricalData = useSelector(state => state.electricalReducer);
    const doorsData = useSelector(state => state.doorsReducer);
    const kcmData = useSelector(state => state.kcmReducer);

    const allMinis = miniData.miniList;
    const allMaxis = maxiData.maxiList;
    const allChassis = chassisData.chassisList;
    const allRsas = rsaData.rsaList;
    const allRucs = rucData.rucList;
    const allElectricals = electricalData.electricalList;
    const allDoors = doorsData.doorsList;
    const allKcm = kcmData.kcmList;
    const allShippings = useSelector(state => state.shippingReducer).shippingList;
    const allConstants = useSelector(state => state.usersReducer).constants;

    const userPostedMini = allMinis.map(x => { return { ...x, line: "MINI" } });
    const userPostedMaxi = allMaxis.map(x => { return { ...x, line: "MAXI" } });
    const userPostedChassis = allChassis.map(x => { return { ...x, line: "Chassis" } });
    const userPostedRsa = allRsas.map(x => { return { ...x, line: "Refrigeration Sub Assembly" } });
    // console.log(userPostedRsa);
    const userPostedRuc = allRucs.map(x => { return { ...x, line: "RUC" } });
    const userPostedElectical = allElectricals.map(x => { return { ...x, line: "Electrical" } });
    const userPostedDoors = allDoors.map(x => { return { ...x, line: "Doors" } });
    const userPostedKcm = allKcm.map(x => { return { ...x, line: "KCM" } });
    const userPostedShipping = allShippings.map(x => { return { ...x, line: "Shipping", date: x.createdAt } });

    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);

    const [labels, setLabels] = useState(Array.from(Array(52).keys()).map(x => x + 1));
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [efficiencyChartData, setEfficiencyChartData] = useState({ labels: [], datasets: [] });
    const [ManHrsChartData, setManHrsChartData] = useState({ labels: [], datasets: [] });
    const [producedGraph, setProducedGraph] = useState({ labels: [], datasets: [] });
    const [producedGraphData, setProducedGraphData] = useState({
        mini: null,
        maxi: null,
        chassis: null,
        ruc: null,
        shipping: null
    });
    const dispatch = useDispatch()

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.isAdmin)
            dispatch(getAllUsers());
    }, [])

    const columns = [
        {
            title: 'Line',
            dataIndex: "line",
            width: 100,
        },
        {
            title: 'Date',
            dataIndex: "displayDate",
            width: 100,
        },
        {
            title: 'Sum of Shift Hrs',
            dataIndex: "sumOfShiftHrs",
            width: 100,
        },
        {
            title: 'Theoretical Production',
            dataIndex: "averageTheoreticalProduction",
            width: 100,
        },
        {
            title: 'Total Units Production',
            dataIndex: "averageTotalUnitsProduction",
            width: 100,
        },
        {
            title: 'M/HRS. PER Unit',
            dataIndex: "averageMPerUnit",
            width: 100,
        },
        {
            title: 'Theoretical Manhours per unit',
            dataIndex: "averageTheoreticalManhoursPerUnit",
            width: 100,
        },
        {
            title: 'Efficiency',
            dataIndex: "averageEfficiency",
            width: 100,
        },
        {
            title: 'Downtime',
            dataIndex: "downtime",
            width: 100,
        },
        {
            title: 'Change Over Time',
            dataIndex: "changeOverTime",
            width: 100,
        },
        {
            title: 'Comments',
            dataIndex: "comments",
            width: 100,
        },
        {
            title: 'Model',
            dataIndex: "model",
            width: 100,
        },
    ];

    const getChartData = (data, labels, month, year, dateRange, type, dataType) => {
        const averageDays = allConstants.find(x => x.text === "Average Days")?.value?.find(x => x.isSelected).value ?? 5;

        if (data && data.length) {
            if (dataType === 'weekly') {
                const chartData = labels.map(weekNo => {
                    let weekDate = getDateOfWeek(weekNo, year);

                    var first = weekDate.getDate() - weekDate.getDay(); // First day is the day of the month - the day of the week
                    var last = first + 6; // last day is the first day + 6

                    var firstday = new Date(weekDate.setDate(first));
                    var lastday = new Date(weekDate.setDate(last));

                    if (first < 0)
                        lastday = new Date(lastday.setMonth(firstday.getMonth() + 1))

                    firstday.setFullYear(year);
                    lastday.setFullYear(year);

                    let firstDate = new Date(moment(firstday).tz("America/New_York").format('MM/DD/YYYY'));
                    let lastDate = new Date(moment(lastday).tz("America/New_York").format('MM/DD/YYYY'));

                    weekNo = moment(firstDate, "MM-DD-YYYY").week();
                    weekNo--;

                    // // console.log(moment(firstDate).isDST(), firstDate, weekNo);

                    firstDate.setFullYear(year);
                    lastDate.setFullYear(year);

                    const weekData = data.filter(x => (
                        firstDate.getTime() <= ((new Date(moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY'))).getTime()) && lastDate.getTime() >= ((new Date(moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY'))).getTime())
                    ))
                    // console.log(userPostedShipping)
                    // console.log(type, weekData, (weekData.reduce((totalProduced, currentValue) => totalProduced += (+currentValue.totalUnitsProduction || 0), 0)).toFixed(2));
                    if(type === 'shipping_produced'){
                        // console.log(data, weekData)
                    }
                    return {
                        show: month === "all" ? true : dateRange ? (weekNo >= getWeekNumber(dateRange.firstday) && weekNo <= getWeekNumber(dateRange.lastday)) : (month === firstday.getMonth() || month === lastday.getMonth()),
                        data: weekData.length ?
                            type === "efficiency" ? weekData.reduce((sumEfficiency, currentValue) => sumEfficiency += ((+currentValue.efficiency * 100) || 0), 0) / averageDays
                                : type === "totalActualManHrs" ? weekData.reduce((sumActualManHrs, currentValue) => sumActualManHrs += (+currentValue.actualManHrs || 0), 0) / averageDays
                                    : type === "theoreticalManHrs" ? weekData.reduce((sumTheoreticalManhoursPerUnit, currentValue) => sumTheoreticalManhoursPerUnit += ((+currentValue.theoreticalManhoursPerUnit * +currentValue.totalUnitsProduction) || 0), 0) / averageDays
                                        : type === "trendline" ? weekData.reduce((sumTheoreticalManhoursPerUnit, currentValue) => sumTheoreticalManhoursPerUnit += +currentValue.totalUnitsProduction, 0)
                                            : type === "manhour" ? (weekData.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0)).toFixed(2) / weekData.length
                                                : (type == "mini_produced" || type == "maxi_produced" || type == "ruc_produced" || type == "chassis_produced") ? (weekData.reduce((totalProduced, currentValue) => totalProduced += (+currentValue.totalUnitsProduction || 0), 0))
                                                    : type === "shipping_produced" ? (weekData.reduce((sumOfShipped, currentValue) => sumOfShipped += (+currentValue.qtyShippied || 0), 0))
                                                        : weekData.reduce((sumPackaged, currentValue) => sumPackaged += (+currentValue.packaged || 0), 0)
                            : 0,
                        label: weekNo
                    };
                })
                // // console.log(chartData);
                return chartData.filter(x => x.show);

            } else {
                labels = month === "all" ?
                    getDates(new Date(year, 0), new Date(year, 11, 31)) :
                    dateRange ?
                        getDates(new Date(moment(dateRange.firstday).format('MM/DD/YYYY')), new Date(moment(dateRange.lastday).format('MM/DD/YYYY'))) :
                        getDates(new Date(year, month), new Date(year, month + 1, 0))
                const chartData = labels.map((date, i) => {
                    const dailyData = data.filter(x => moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY'))
                    // console.log(dailyData, (dailyData.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0)).toFixed(2));
                    return {
                        show: true,
                        data: dailyData.length ?
                            type === "efficiency" ? dailyData.reduce((sumEfficiency, currentValue) => sumEfficiency += ((+currentValue.efficiency * 100) || 0), 0) / averageDays
                                : type === "totalActualManHrs" ? dailyData.reduce((sumActualManHrs, currentValue) => sumActualManHrs += (+currentValue.actualManHrs || 0), 0) / averageDays
                                    : type === "theoreticalManHrs" ? dailyData.reduce((sumTheoreticalManhoursPerUnit, currentValue) => sumTheoreticalManhoursPerUnit += ((+currentValue.theoreticalManhoursPerUnit * +currentValue.totalUnitsProduction) || 0), 0) / averageDays
                                        : type === "trendline" ? dailyData.reduce((sumTheoreticalManhoursPerUnit, currentValue) => sumTheoreticalManhoursPerUnit += +currentValue.totalUnitsProduction, 0)
                                            : type === "manhour" ? (dailyData.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0)).toFixed(2) / dailyData.length
                                                : (type == "mini_produced" || type == "maxi_produced" || type == "ruc_produced" || type == "chassis_produced") ? (dailyData.reduce((totalProduced, currentValue) => totalProduced += (+currentValue.totalUnitsProduction || 0), 0))
                                                    : type === "shipping_produced" ? (dailyData.reduce((sumOfShipped, currentValue) => sumOfShipped += (+currentValue.qtyShippied || 0), 0))
                                                        : dailyData.reduce((sumPackaged, currentValue) => sumPackaged += (+currentValue.packaged || 0), 0)
                            : 0,
                        label: i + 1
                    };
                })
                return chartData.filter(x => x.show);
            }

        } else return []
    }

    const getDates = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    const getDateOfWeek = (weekNo, year) => {
        var d = (1 + (weekNo - 1) * 7); // 1st of January + 7 days for each week
        return new Date(year, 0, d);
    }

    const onTradeLineFilter = (params, type) => {
        let chartData = [];
        let itemsData = [];
        switch (params.filterBy.toLowerCase()) {
            case "yearly":
                // if (type === "manhour") {
                //     let yearlytheoreticalManHrs = getChartData(getDataSet(params.line), labels, 'all', params.year, null, 'theoreticalManHrs', params.dataType);
                //     let yearlytotalActualManHrs = getChartData(getDataSet(params.line), labels, 'all', params.year, null, 'totalActualManHrs', params.dataType);
                //     setManHrsChartData({
                //         labels: yearlytheoreticalManHrs.map(x => x.label),
                //         datasets: [
                //             {
                //                 label: 'Theoretical Man Hrs',
                //                 type: 'line',
                //                 data: yearlytheoreticalManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(68, 114, 196,1)',
                //                 borderColor: 'rgba(68, 114, 196,1)',
                //             },
                //             {
                //                 label: 'Total Actual Man Hrs',
                //                 data: yearlytotalActualManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(237, 124, 49,1)',
                //             }
                //         ],
                //     })
                // } else {
                    itemsData = getDataSet(params.line);
                    if(params.model) itemsData = itemsData.filter(x => x.model === params.model);
                    chartData = getChartData(itemsData, labels, 'all', params.year, null, type, params.dataType);
                    const yearlyData = {
                        labels: chartData.map(x => x.label),
                        datasets: [
                            {
                                label: '',
                                data: chartData.map(x => x.data),
                                backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                options: {
                                    legend: {
                                       display: false
                                    },
                                    title: {
                                        display: false
                                    },
                                    subtitle: {
                                        display: false
                                    },
                               }
                            },
                        ],
                    };

                    if(type == "shipping_produced"){
                        chartData = getChartData(userPostedShipping, labels, 'all', params.year, null, type, params.dataType);
                        return chartData.map(x => x.data);
                    }

                    if(type == "mini_produced" || type == "maxi_produced" || type == "ruc_produced" || type == "chassis_produced" || type == "shipping_produced"){
                        return yearlyData;
                    }

                    type === 'efficiency' ? setEfficiencyChartData(yearlyData) : type === "manhour" ? setManHrsChartData(yearlyData) : setChartData(yearlyData);
                // }


                break;
            case "month":
                // if (type === "manhour") {
                //     let monthlytheoreticalManHrs = getChartData(getDataSet(params.line), labels, params.month, params.year, null, 'theoreticalManHrs', params.dataType);
                //     let monthlytotalActualManHrs = getChartData(getDataSet(params.line), labels, params.month, params.year, null, 'totalActualManHrs', params.dataType);
                //     setManHrsChartData({
                //         labels: monthlytheoreticalManHrs.map(x => x.label),
                //         datasets: [
                //             {
                //                 label: 'Theoretical Man Hrs',
                //                 type: 'line',
                //                 data: monthlytheoreticalManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(68, 114, 196,1)',
                //                 borderColor: 'rgba(68, 114, 196,1)',
                //             },
                //             {
                //                 label: 'Total Actual Man Hrs',
                //                 data: monthlytotalActualManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(237, 124, 49,1)',
                //             }
                //         ],
                //     })
                // } else {
                    itemsData = getDataSet(params.line);
                    if(params.model) itemsData = itemsData.filter(x => x.model === params.model);
                    chartData = getChartData(itemsData, labels, params.month, params.year, null, type, params.dataType);
                    const monthlyData = {
                        labels: chartData.map(x => x.label),
                        datasets: [
                            {
                                label: '',
                                data: chartData.map(x => x.data),
                                backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                legend: {
                                    display: false
                                 },
                                 title: {
                                     display: false
                                 },
                                 subtitle: {
                                     display: false
                                 },
                            },
                        ],
                    }

                    if(type == "shipping_produced"){
                        chartData = getChartData(userPostedShipping, labels, params.month, params.year, null, type, params.dataType);
                        return chartData.map(x => x.data);
                    }

                    if(type == "kcm_produced" || type == "mini_produced" || type == "maxi_produced" || type == "ruc_produced" || type == "chassis_produced" || type == "shipping_produced"){
                        return monthlyData;
                    }

                    type === 'efficiency' ? setEfficiencyChartData(monthlyData) : type === "manhour" ? setManHrsChartData(monthlyData) : setChartData(monthlyData);
                // }
                break;
            case "daterange":
                // if (type === "manhour") {
                //     let daterangetheoreticalManHrs = getChartData(getDataSet(params.line), labels, null, params.year, params, 'theoreticalManHrs', params.dataType);
                //     let daterangetotalActualManHrs = getChartData(getDataSet(params.line), labels, null, params.year, params, 'totalActualManHrs', params.dataType);
                //     setManHrsChartData({
                //         labels: daterangetheoreticalManHrs.map(x => x.label),
                //         datasets: [
                //             {
                //                 label: 'Theoretical Man Hrs',
                //                 type: 'line',
                //                 data: daterangetheoreticalManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(68, 114, 196,1)',
                //                 borderColor: 'rgba(68, 114, 196,1)',
                //             },
                //             {
                //                 label: 'Total Actual Man Hrs',
                //                 data: daterangetotalActualManHrs.map(x => x.data),
                //                 backgroundColor: 'rgba(237, 124, 49,1)',
                //             }
                //         ],
                //     })
                // } else {
                    itemsData = getDataSet(params.line);
                    if(params.model) itemsData = itemsData.filter(x => x.model === params.model);
                    chartData = getChartData(itemsData, labels, null, params.year, params, type, params.dataType);
                    const dateRangeData = {
                        labels: chartData.map(x => x.label),
                        datasets: [
                            {
                                label: '',
                                data: chartData.map(x => x.data),
                                backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                legend: {
                                    display: false
                                 },
                                 title: {
                                     display: false
                                 },
                                 subtitle: {
                                     display: false
                                 },
                            },
                        ],
                    }

                    if(type == "shipping_produced"){
                        chartData = getChartData(userPostedShipping, labels, null, params.year, params, type, params.dataType);
                        return chartData.map(x => x.data);
                    }

                    if(type == "kcm_produced" || type == "mini_produced" || type == "maxi_produced" || type == "ruc_produced" || type == "chassis_produced" || type == "shipping_produced"){
                        return dateRangeData;
                    }

                    type === 'efficiency' ? setEfficiencyChartData(dateRangeData) : type === "manhour" ? setManHrsChartData(dateRangeData) : setChartData(dateRangeData);
                // }

                break;
            default:
                break;
        }
    }

    const getWeekNumber = (d) => {
        // Copy date so don't modify original
        d = new Date(+d);
        const year = d.getFullYear();
        d.setHours(0, 0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        var yearStart = new Date(year, 0, 1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        // Return array of year and week number
        return weekNo + 1;
    }

    const getDataSet = (line) => {
        // // console.log(line, filteredDataSource.filter(x => x.line.toLowerCase() === line.toLowerCase()), dataSource.filter(x => x.line.toLowerCase() === line.toLowerCase()));
        return dataSource.filter(x => x.line.toLowerCase() === line.toLowerCase())
    }

    useEffect(() => {
        setData();
        if (miniData.loaded && userPostedMini.length) {
            const dataSet = getDataSet('mini')
            if (!chartData.datasets.length)
                setChartData({
                    labels,
                    datasets: [
                        {
                            label: 'Total',
                            data: getChartData(dataSet, labels, 'all', (new Date()).getFullYear()).filter(x => x.show).map(x => x.data),
                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                        },
                    ],
                });
            if (!efficiencyChartData.datasets.length)
                setEfficiencyChartData({
                    labels,
                    datasets: [
                        {
                            label: 'Total %',
                            data: getChartData(
                                dataSet,
                                labels,
                                'all',
                                (new Date()).getFullYear(),
                                null,
                                'efficiency'
                            ).filter(x => x.show).map(x => x.data),
                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                        },
                    ],
                });
            if (!ManHrsChartData.datasets.length) {
                setManHrsChartData({
                    labels,
                    datasets: [
                        // {
                        //     label: 'Theoretical Man Hrs ',
                        //     type: 'line',
                        //     data: getChartData(
                        //         dataSet,
                        //         labels,
                        //         'all',
                        //         (new Date()).getFullYear(),
                        //         null,
                        //         'theoreticalManHrs'
                        //     ).filter(x => x.show).map(x => x.data),
                        //     backgroundColor: 'rgba(68, 114, 196,1)',
                        //     borderColor: 'rgba(68, 114, 196,1)',
                        // },
                        // {
                        //     label: 'Total Actual Man Hrs',
                        //     data: getChartData(
                        //         dataSet,
                        //         labels,
                        //         'all',
                        //         (new Date()).getFullYear(),
                        //         null,
                        //         'totalActualManHrs'
                        //     ).filter(x => x.show).map(x => x.data),
                        //     backgroundColor: 'rgba(237, 124, 49,1)',
                        // }
                        {
                            label: 'Total',
                            data: getChartData(
                                dataSet,
                                labels,
                                'all',
                                (new Date()).getFullYear(),
                                null,
                                'manhour'
                            ).filter(x => x.show).map(x => x.data),
                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                        }
                    ],
                });
            }
        }
    }, [miniData])

    useEffect(() => {
        setData()
    }, [maxiData])

    useEffect(() => {
        setData()
    }, [rucData])

    useEffect(() => {
        setData()
    }, [rsaData])

    useEffect(() => {
        setData()
    }, [chassisData])

    useEffect(() => {
        setData()
    }, [doorsData])

    useEffect(() => {
        setData()
    }, [electricalData])

    const setData = () => {
        // console.log(kcmData);
        if (miniData.loaded && electricalData.loaded && maxiData.loaded && rucData.loaded && rsaData.loaded && doorsData.loaded && chassisData.loaded && kcmData.loaded) {
            let list = []
            list = set(userPostedMini, list, 'mini');
            list = set(userPostedMaxi, list, 'maxi');
            list = set(userPostedKcm, list, 'kcm');
            list = set(userPostedRuc, list, 'ruc');
            list = set(userPostedChassis, list, 'chassis');
            list = set(userPostedRsa, list, 'rsa');
            list = set(userPostedElectical, list);
            list = set(userPostedDoors, list);
            list = set(userPostedShipping, list);
            list.filter(x => x.line.toLowerCase() === 'mini').forEach(x => {
                console.log(x.changeOverTime);
            })
            setDataSource(list);
            initialFilter(list)
        }
    }

    const initialFilter = (list) => {
        // this gives an object with dates as keys
        const groups = list.reduce((groups, data) => {
            const date = data.date.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(data);
            return groups;
        }, {});

        // // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                data: groups[date]
            };
        });

        let sortedList = groupArrays.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date); // sort by latest date
        });

        let allLineCombined = [];

        sortedList.forEach(group => {
            let mini = group.data.filter(x => x.line?.toLowerCase() === 'mini');
            let maxi = group.data.filter(x => x.line?.toLowerCase() === 'maxi');
            let kcm = group.data.filter(x => x.line?.toLowerCase() === 'kcm');
            let ruc = group.data.filter(x => x.line?.toLowerCase() === 'ruc');
            let chassis = group.data.filter(x => x.line?.toLowerCase() === 'chassis');
            let rsa = group.data.filter(x => x.line === 'Refrigeration Sub Assembly');
            let electrical = group.data.filter(x => x.line?.toLowerCase() === 'electrical');
            let doors = group.data.filter(x => x.line?.toLowerCase() === 'doors');
            // console.log(rsa)
            let combined = []
                .concat(getFilteredData("MINI", mini))
                .concat(getFilteredData("MAXI", maxi))
                .concat(getFilteredData("KCM", kcm))
                .concat(getFilteredData("RUC", ruc))
                .concat(getFilteredData("Chassis", chassis))
                .concat(getFilteredData("rsa", rsa))
                .concat(getFilteredData("Electrical", electrical))
                .concat(getFilteredData("Doors", doors));

            allLineCombined = allLineCombined.concat(combined);

        })
        setFilteredDataSource(allLineCombined);
    }

    const set = (data, list, type) => {
        // if(type == 'mini') console.log(data)
        if (data && data.length) {
            const cycleTime = allConstants.find(x => x.text === `Cycle Time ${type?.toLowerCase()}`)?.value.find(x => x.isSelected)?.value;
            data.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date); // sort by latest date
            }).map(x => {
                const totalUnitsProduction = (x.doors ?? 0) + (x.controlBox ?? 0) + (x.subAssemblyTotal ?? 0) + (x.majorChassisAssy ?? 0) + (x.totalChassis ?? 0) + (x.packaged ?? 0);
                const theoreticalProduction = (type == "maxi") ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time maxi`).value) + 60) :
                type == "kcm" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time kcm`).value) + 60) :
                    type == "mini" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time mini`).value) + 60) :
                        type == "chassis" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time chassis`).value)) :
                            type == "rsa" ? (x.shiftHours ?? 0) * 60 * 60 / 120 : 
                                type == "ruc" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time ruc`).value) + 60) : null;
                const actualManHrs = ((x.totalStaff ?? 0) * (x.shiftHours ?? 0)) + (x.adjustedHours ?? 0);
                const totalManHrs = actualManHrs / totalUnitsProduction;
                const effTP = type == "mini" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time mini`).value) + 60) 
                        : (type == "maxi") ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time maxi`).value) + 60) 
                        : (type == "kcm") ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time kcm`).value) + 60) 
                        : type == "ruc" ? (x.shiftHours ?? 0) * 60 * 60 / (getCycleTime(x.date, allConstants.find(x => x.text === `Cycle Time ruc`).value) + 60) 
                        : null;
                const efficiency = type == "rsa" ? ((totalUnitsProduction * getProposedCrewing(type)) / (((theoreticalProduction / 8) * x.shiftHours) * (x.totalStaff ?? 0))).toFixed(2)
                : (type == "mini") ? ((x.packaged * getProposedCrewing(type)) / (effTP * (x.totalStaff ?? 0))).toFixed(2) 
                : type == "ruc" ? ((x.packaged * getProposedCrewing(type)) / (effTP * x.totalStaff ?? 0)).toFixed(2)
                : (type == "maxi" || type == "kcm") ? ((x.packaged * getProposedCrewing(type)) / (effTP * x.totalStaff ?? 0)).toFixed(2)
                : type == "chassis" ? (totalUnitsProduction / theoreticalProduction).toFixed(2) 
                : null;
                
                if(type === "mini"){
                    // console.log(x.changeOvertime)
                }

                list.push({
                    ...x,
                    changeOverTime: x.changeOvertime ?? 0,
                    displayDate: isValidDate(new Date(x.date)) ? moment(x.date.split('T')[0], 'YYYY-MM-DD').format('ddd, MMMM Do YYYY') : '',
                    actualManHrs: actualManHrs.toFixed(2),
                    // theoreticalProduction: theoreticalProduction ? (type == "chassis" ? theoreticalProduction : ((theoreticalProduction / 8) * x.shiftHours).toFixed(2)) : theoreticalProduction,
                    theoreticalProduction: theoreticalProduction,
                    totalUnitsProduction: totalUnitsProduction.toFixed(2),
                    mPerUnit: (totalManHrs).toFixed(2),
                    theoreticalManhoursPerUnit: type == "kcm" || type == "mini" || type == "maxi" || type == "ruc" || type == "rsa" || type == "chassis" ? ((getProposedCrewing(type) * 9.9) / theoreticalProduction).toFixed(2) : null,
                    efficiency: efficiency,
                    displayEfficiency: `${(efficiency * 100).toFixed(2)}%`,
                })
            })
            // console.log(type, list.filter(x => x.line == 'KCM'));
            return list;
        } else return list
    }

    // method to get the cycle time based on the current month
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    function getCycleTime(date, data){
        let d = new Date(date);
        let month = months[d.getMonth()];
        let result = data.find(x => x.month === month && x.year === d.getFullYear());
        // // console.log(result ? result.value : 360);
        return result ? result.value : 360;
    }

    const getProposedCrewing = (type) => {
        switch (type) {
            case "mini":
                return allConstants.find(x => x.text === "MINI Proposed Crewing") ? allConstants.find(x => x.text === "MINI Proposed Crewing").value[0] : 0;
            case "maxi":
                return allConstants.find(x => x.text === "MAXI Proposed Crewing") ? allConstants.find(x => x.text === "MAXI Proposed Crewing").value[0] : 0;
            case "kcm":
                return allConstants.find(x => x.text === "KCM Proposed Crewing") ? allConstants.find(x => x.text === "KCM Proposed Crewing").value[0] : 0;
            case "ruc":
                // // console.log(allConstants.find(x => x.text === "RUC Proposed Crewing") ? allConstants.find(x => x.text === "RUC Proposed Crewing").value[0] : 0)
                return allConstants.find(x => x.text === "RUC Proposed Crewing") ? allConstants.find(x => x.text === "RUC Proposed Crewing").value[0] : 0;
            case "rsa":
                return allConstants.find(x => x.text === "Refrigeration Sub Assembly Proposed Crewing") ? allConstants.find(x => x.text === "Refrigeration Sub Assembly Proposed Crewing").value[0] : 0;
            case "chassis":
                return allConstants.find(x => x.text === "Chasis Proposed Crewing") ? allConstants.find(x => x.text === "Chasis Proposed Crewing").value[0] : 0;
            default:
                return 0;
        }
    }

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const onFilter = (params) => {
        // setIsFiltered(true);
        // console.log(params)
        var ds = [];
        switch (params.filterBy.toLowerCase()) {
            case "date":
                const filterDate = moment(params.date).format('YYYY-MM-DD');
                ds = dataSource.filter(x =>
                    (params.line === "ALL" ? true : x.line === params.line) &&
                    isValidDate(new Date(x.date)) && filterDate == x.date.split('T')[0]
                );
                break;
            case "weekno":
                let firstDate = new Date(moment(params.firstday).format('MM/DD/YYYY'))
                let lastDate = new Date(moment(params.lastday).format('MM/DD/YYYY'))
                firstDate.setFullYear(params.year);
                lastDate.setFullYear(params.year);
                ds = dataSource
                    .filter(x => (params.line === "ALL" ? true : x.line === params.line) &&
                        isValidDate(new Date(x.date)) &&
                        firstDate.getTime() <= ((new Date(moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY'))).getTime()) && lastDate.getTime() >= ((new Date(moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/DD/YYYY'))).getTime())
                    )
                break;
            case "month":
                var date = new Date();
                date.setMonth(params.month);
                date.setFullYear(params.year);
                ds = dataSource
                    .filter(x => (params.line === "ALL" ? true : x.line === params.line)
                        && checkDate(x.date, params)
                        // && isValidDate(new Date(x.date.split('T')[0], 'YYYY-MM-DD')) 
                        // && moment(date).format('MM/YY') === moment(x.date.split('T')[0], 'YYYY-MM-DD').format('MM/YY')
                    )
                
                break;
            default:
                break;
        }
        if(params.model){
            ds = ds.filter(x => {
                if(x.model){
                    return x.model.toLowerCase() === params.model.toLowerCase();
                }
                else return false;
            });
        }
        // console.log(ds);
        setFilteredDataSource(params.line === "ALL" ?
            []
                .concat(getFilteredData("MINI", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("MAXI", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("KCM", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("RUC", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("Chassis", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("Refrigeration Sub Assembly", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("Electrical", ds, params.filterBy.toLowerCase() === "weekno", params))
                .concat(getFilteredData("Doors", ds, params.filterBy.toLowerCase() === "weekno", params))

            : getFilteredData(params.line, ds, params.filterBy.toLowerCase() === "weekno", params));
    }

    const checkDate = (date, params) => {
        let d = new Date(date);
        let status = false;
        if(params.month === d.getMonth() && Number(params.year) === d.getFullYear()){
            status = true;
        }
        return status;
    }

    const getFilteredData = (type, dataSource, isWeekly, params) => {
        
        // return dataSource.length ? dataSource.map(x => {
        //     return {
        //         line: type,
        //         displayDate: x.displayDate,
        //         sumOfShiftHrs: x.shiftHours,
        //         averageTheoreticalProduction: x.theoreticalProduction,
        //         averageTotalUnitsProduction: x.totalUnitsProduction,
        //         averageMPerUnit: x.mPerUnit,
        //         averageTheoreticalManhoursPerUnit: x.theoreticalManhoursPerUnit,
        //         averageEfficiency: x.efficiency,
        //         downtime: x.downtimeMins,
        //         changeOverTime: x.changeOverTime,
        //         comments: x.comment,
        //         model: x.model
        //     }
        // }) : []

        const averageDays = allConstants.find(x => x.text === "Average Days")?.value?.find(x => x.isSelected)?.value ?? 5;
        if(type == 'rsa') {
            // console.log(dataSource);
            type = 'refrigeration sub assembly';
        }
        dataSource = dataSource.filter(x => x.line.toLowerCase() === type.toLowerCase());
        const displayDate = dataSource.length ?
            isWeekly ? `${moment(params.firstday).format('Do MMM, YYYY')} - ${moment(params.lastday).format('Do MMM, YYYY')}` :
                params?.filterBy == "Month" ? moment(new Date(params?.year, params?.month)).format('MMM, YYYY') :
                    isValidDate(new Date(dataSource[0].date)) ? moment(dataSource[0].date.split('T')[0], 'YYYY-MM-DD').format('ddd, MMMM Do YYYY') : ''
            : 0;

        // let avrgTP = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalProduction || 0, 0) / (isWeekly ? averageDays : dataSource.length)).toFixed(2);
        let avrgTP = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalProduction || 0, 0)).toFixed(2);
        if(type === "Chassis"){
            avrgTP = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalProduction || 0, 0)).toFixed(2);
        }
        let totalStaffs = 0;
        let mmrEfficiency = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.efficiency || 0), 0) / dataSource.length).toFixed(2);
        totalStaffs = dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.totalStaff || 0, 0);
        totalStaffs = totalStaffs / dataSource.length;
        let totalUnitsProduction = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.totalUnitsProduction || 0, 0));
        if(type.toLowerCase() === "ruc" || type.toLowerCase() === "mini" || type.toLowerCase() === "maxi"){
            // avrgTP = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalProduction || 0, 0)).toFixed(2);
            // // console.log(dataSource);
            // // console.log((dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalManhoursPerUnit || 0, 0) / dataSource.length));
            // // console.log(allConstants.find(x => x.text === "RUC Proposed Crewing"));
            // // console.log(getProposedCrewing(type.toLowerCase()), +(dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.shiftHours || 0), 0)).toFixed(2), +avrgTP);
            // // console.log(((getProposedCrewing(type) * +(dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.shiftHours || 0), 0)).toFixed(2)) / +avrgTP))
            // // console.log((dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0) / (isWeekly ? averageDays : dataSource.length)).toFixed(2));
            // // console.log(dataSource);
            // // console.log(totalUnitsProduction, getProposedCrewing(type.toLowerCase()), avrgTP, totalStaffs)
            // // console.log(((totalUnitsProduction * getProposedCrewing(type.toLowerCase())) / (avrgTP * (totalStaffs ?? 0))))
            // mmrEfficiency = (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.efficiency || 0), 0) / dataSource.length).toFixed(2)
            
        }

        if(type.toLowerCase() === 'mini'){
            dataSource.forEach(x => {
                // console.log(x.changeOverTime);
            });
        }

        // check if the array objects have the same model or not
        // let ifSameModel = false;
        // if(dataSource.length > 1){
        //     let model = dataSource[0].model;
        //     let isSameModel = dataSource.every(x => x.model === model);
        //     ifSameModel = isSameModel;
        // }

        
        
        let result = dataSource.length ? [{
            line: type,
            displayDate: displayDate,
            date: dataSource[0].date,
            sumOfShiftHrs: (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.shiftHours || 0), 0)).toFixed(2),
            averageTheoreticalProduction: avrgTP,
            // averageTotalUnitsProduction: (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.totalUnitsProduction || 0, 0) / (isWeekly ? averageDays : dataSource.length)).toFixed(2),
            averageTotalUnitsProduction: Math.round((dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.totalUnitsProduction || 0, 0)).toFixed(2)),
            // averageMPerUnit: (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0) / (isWeekly ? averageDays : dataSource.length)).toFixed(2),
            averageMPerUnit: (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.mPerUnit || 0), 0)).toFixed(2),
            // averageTheoreticalManhoursPerUnit: (dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.theoreticalManhoursPerUnit || 0, 0) / dataSource.length).toFixed(2),
            averageTheoreticalManhoursPerUnit: type === "Doors" || type === "Electrical" ? 0 : ((getProposedCrewing(type.toLowerCase()) * +(dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += (+currentValue.shiftHours || 0), 0)).toFixed(2)) / +avrgTP).toFixed(2),
            // averageEfficiency: ((dataSource.reduce((sumOfShiftHrs, currentValue) => sumOfShiftHrs += +currentValue.efficiency || 0, 0) / (isWeekly ? averageDays : dataSource.length)) * 100).toFixed(0) + "%",
            // averageEfficiency: type === "Doors" || type === "Electrical" ? 0 : Math.round((((totalUnitsProduction * getProposedCrewing(type.toLowerCase())) / (avrgTP * (totalStaffs ?? 0))) * 100)) + "%",
            averageEfficiency: type === "Doors" || type === "Electrical" ? 0 : Math.round((mmrEfficiency * 100)) + "%",
            downtime: dataSource[0].downtimeMins,
            changeOverTime: dataSource.reduce((sumOfShiftHrs, x) => sumOfShiftHrs += +x.changeOverTime || 0, 0),
            comments: dataSource[0].comment,
            model: dataSource[0].model
        }] : []

        return result;
    }

        // EXPORT FUNCTIONS START
    const [isExportModalVisible, setExportIsModalVisible] = useState(false);
    const years = [2019, 2020, 2021, 2022, 2023, 2024];
    const months_excel = [{ value: 0, label: 'January' }, { value: 1, label: 'February' }, { value: 2, label: 'March' }, { value: 3, label: 'April' }, { value: 4, label: 'May' }, { value: 5, label: 'June' }, { value: 6, label: 'July' }, { value: 7, label: 'August' }, { value: 8, label: 'September' }, { value: 9, label: 'October' }, { value: 10, label: 'November' }, { value: 11, label: 'December' }];
    const showExportModal = () => {
        setExportIsModalVisible(true);
    };

    const handleExportOk = () => {
        exportData(formatExportCSVData(filteredDataSource));
        setExportIsModalVisible(false);
    };

    const handleExportCancel = () => {
        setExportIsModalVisible(false);
    };

    const exportData = async (filteredDataSource, year = null, month = null) => {
        try {
            let csv = await parseAsync(filteredDataSource, {});
            var filename, link;
            if (csv == null) return;
            filename = `dashboard_data_${year ? year + "_" : ""}${month ? months_excel[month].label.toLowerCase() + "_" : ""}${moment().format('MM-DD-YYYY')}.csv`;
            var blob = new Blob(["\ufeff", csv]);
            var url = URL.createObjectURL(blob);
            link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.error(err);
        }
    }

    const onExportFinish = (values) => {
        let data = filteredDataSource.filter(x => {
            let date = new Date(x.date);
            let status = date.getFullYear() == values.export_year && date.getMonth() == values.export_month;
            return status;
        })
        data = formatExportCSVData(data);
        // console.log(data.length)
        if(data.length > 0) exportData(data, values.export_year, values.export_month);
    }

    const formatExportCSVData = (data) => {
        data = data.map(x => {
            return {
                'Line': x.line,
                'Date': x.displayDate,
                'Sum of Shift Hrs': x.sumOfShiftHrs,
                'Theoretical Production': x.averageTheoreticalProduction,
                'Total Units Production': x.averageTotalUnitsProduction,
                'M/HRS. PER Unit': x.averageMPerUnit,
                'Theoretical Manhours per unit': x.averageTheoreticalManhoursPerUnit,
                'Efficiency': x.averageEfficiency,
                'Downtime': x.downtime,
                'Change Over Time': x.changeOverTime,
                'Comments': x.comments,
                'Model': x.model,
            }
        })
        return data;
    }

    // EXPORT FUNCTIONS END

    // get week start date from week number 
    const getWeekStartDate = (weekNumber, year) => {
        let d = new Date(year, 0, 1 + (weekNumber) * 7);
        let day = d.getDay();
        let diff = (d.getDate() - day) + (day == 0 ? -6 : 1);
        let date = new Date(d.setDate(diff));
        return `WB ${date.getDate()}-${date.getMonth() + 1}`;
    }

    const setGraph = (data, params) => {
        if(data.mini && data.maxi && data.ruc && data.chassis){
        let mini_maxi_sum = data.mini.datasets[0].data.map(function (num, idx) {
            return num + data.maxi.datasets[0].data[idx];
          });
        let min_maxi_ruc_sum = mini_maxi_sum.map(function (num, idx) {
            return num + data.ruc.datasets[0].data[idx];
          });
        // console.log(data.mini.labels);
        let labels = data.mini.labels;
        let weekLabels = labels.map(x => getWeekStartDate(x, params.year))
        // console.log(weekLabels);
        const graphData = {
            labels: weekLabels,
            datasets: [
                {
                    label: 'Mini Produced',
                    data: data.mini.datasets[0].data,
                    backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },
                {
                    label: 'Maxi Produced',
                    data: data.maxi.datasets[0].data,
                    backgroundColor: 'rgba(50, 168, 82, 0.7)',
                },
                {
                    label: 'Ruc Produced',
                    data: data.ruc.datasets[0].data,
                    backgroundColor: 'rgba(194, 46, 29, 0.7)',
                },
                {
                    label: 'KCM Produced',
                    data: data.kcm.datasets[0].data,
                    backgroundColor: 'rgba(394, 36, 19, 0.7)',
                },
                {
                    label: 'Chassis Produced',
                    data: data.chassis.datasets[0].data,
                    backgroundColor: 'rgba(69, 15, 135, 0.7)',
                },
                {
                    label: '3 Lines Total',
                    data: min_maxi_ruc_sum,
                    backgroundColor: 'rgba(135, 15, 55, 0.7)',
                },
                {
                    label: 'Total Shipping',
                    data: data.shipping,
                    backgroundColor: 'rgba(252, 186, 3, 0.7)',
                },
            ],
        }
        setProducedGraph(graphData);
        }
    }

    return (
        <div>
            <DefaultLayout>
                <h3>Dashboard</h3>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Dashboard" key="1">
                        <div className="d-flex justify-content-end">
                            <Button type="primary" onClick={showExportModal}><FileExcelOutlined /> Export as Excel</Button>
                        </div>

                        <Filter isStriped hasModel
                            onFilter={onFilter}
                            onReset={() => {
                                initialFilter(dataSource);
                                // setIsFiltered(false);
                            }}
                            hasLine
                        />

                        <Table columns={columns} dataSource={filteredDataSource} />
                    </TabPane>

                    <TabPane tab="Trendline" key="2">
                        <Filter isStriped hasModel 
                            onFilter={
                                // onTradeLineFilter
                                (params) => onTradeLineFilter(params, "trendline")
                            }
                            onReset={() => {
                                setChartData({
                                    labels,
                                    datasets: [
                                        {
                                            label: 'Total',
                                            data: getChartData(userPostedMini, labels, 'all', (new Date()).getFullYear()).filter(x => x.show).map(x => x.data),
                                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                        },
                                    ],
                                });
                            }}
                            hasLine
                            isTrendLine
                        />
                        {chartData.datasets.length > 0 && <Bar options={options} data={chartData} style={{ maxHeight: 500 }} />}

                        {/* <h5>Development in Progress</h5> */}

                    </TabPane>

                    <TabPane tab="Manhour Chart" key="3">
                        <Filter isStriped hasModel 
                            onFilter={(params) => {
                                onTradeLineFilter(params, "manhour")
                            }}
                            onReset={() => {
                                setChartData({
                                    labels,
                                    datasets: [
                                        {
                                            label: '',
                                            data: getChartData(userPostedMini, labels, 'all', (new Date()).getFullYear()).filter(x => x.show).map(x => x.data),
                                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                        },
                                    ],
                                });
                            }}
                            hasLine
                            isTrendLine
                        />
                        {ManHrsChartData.datasets.length > 0 && <Bar options={options2} data={ManHrsChartData} style={{ maxHeight: 500 }} />}
                        
                    </TabPane>

                    <TabPane tab="Efficiency Chart" key="4">
                        {/* <Filter isStriped
                            onFilter={(params) => onTradeLineFilter(params, "efficiency")}
                            onReset={() => {
                                setChartData({
                                    labels,
                                    datasets: [
                                        {
                                            label: 'Total',
                                            data: getChartData(userPostedMini, labels, 'all', (new Date()).getFullYear()).filter(x => x.show).map(x => x.data),
                                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                        },
                                    ],
                                });
                            }}
                            isEfficiency="true"
                            hasLine
                            isTrendLine
                        />
                        {efficiencyChartData.datasets.length > 0 && <Bar options={options3} data={efficiencyChartData} style={{ maxHeight: 500 }} />} */}

                        <h5>Development in Progress</h5>

                    </TabPane>

                    <TabPane tab="Production Chart" key="6">

                        <Filter isStriped
                            onFilter={(params) => {
                                let data = {
                                    maxi: onTradeLineFilter({...params, line: 'MAXI'}, "maxi_produced"),
                                    mini: onTradeLineFilter({...params, line: 'MINI'}, "mini_produced"),
                                    ruc: onTradeLineFilter({...params, line: 'RUC'}, "ruc_produced"),
                                    kcm: onTradeLineFilter({...params, line: 'KCM'}, "kcm_produced"),
                                    chassis: onTradeLineFilter({...params, line: 'Chassis'}, "chassis_produced"),
                                    shipping: onTradeLineFilter({...params, line: 'Shipping'}, "shipping_produced"),
                                }
                                console.log(data)
                                setProducedGraphData(data);
                                setGraph(data, params);
                            }}
                            onReset={() => {
                                setProducedGraphData({});
                            }}
                            isTrendLine
                        />

                        {producedGraph.datasets.length > 0 && <Bar options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                    lineWidth: 20
                                },
                                title: {
                                    display: true,
                                    text: 'Total Produced',
                                },
                            },
                        }} data={producedGraph} style={{ maxHeight: 500 }} />}
                        {/* <ViewProducedGraph data={producedGraphData}></ViewProducedGraph> */}
                    </TabPane>

                    {/* <TabPane tab="Manhour Chart" key="6">
                        <Filter isStriped
                            onFilter={(params) => onTradeLineFilter(params, "manhour")}
                            onReset={() => {
                                setChartData({
                                    labels,
                                    datasets: [
                                        {
                                            label: '',
                                            data: getChartData(userPostedMini, labels, 'all', (new Date()).getFullYear()).filter(x => x.show).map(x => x.data),
                                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                        },
                                    ],
                                });
                            }}
                            hasLine
                            isTrendLine
                        />
                        {ManHrsChartData.datasets.length > 0 && <Bar options={options2} data={ManHrsChartData} style={{ maxHeight: 500 }} />}
                        
                    </TabPane> */}
                    
                    <TabPane tab="Shipping" key="7">
                        <ViewShipping isDashboard />
                    </TabPane>

                    <TabPane tab="Fast Track Shipping" key="8">
                        <ViewShipping isDashboard isFastTracking />
                    </TabPane>

                    {/* Shipping Average  */}
                    <TabPane tab="Shipping Summary by Date" key="9">
                        <ViewShipping isDashboard isShippingByDate />
                    </TabPane>

                    {/* FT Shipping Average  */}
                    <TabPane tab="Fast Track Shipping Summary by Date" key="10">
                        <ViewShipping isDashboard isFTShippingByDate />
                    </TabPane>

                </Tabs>


                <Modal title="Export Excel" visible={isExportModalVisible} 
                    okText="Download All Data"
                    onOk={handleExportOk} onCancel={handleExportCancel}>
                    <Form name="export-form"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{  }}
                        onFinish={onExportFinish}
                        autoComplete="off">
                        <Form.Item label="Select Year" name="export_year" rules={[{ required: true, message: 'Select Year' }]}>
                            <Select placeholder="Select Year">
                                {
                                    years.map(x => <Option key={x} value={x}>{x}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Select Month" name="export_month" rules={[{ required: true, message: 'Select Month' }]}>
                            <Select placeholder="Select Month">
                                {
                                    months_excel.map(x => <Option key={x.value} value={x.value}>{x.label}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Download Monthly Data
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </DefaultLayout>
        </div>
    )
}

export default Dashboard
