import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { useHistory, Link } from 'react-router-dom'
import { Bar } from 'react-chartjs-2';
import { options3 } from './Dashboard';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
);

function ViewProducedGraph(props) {
  const allUsers = useSelector(state => state.usersReducer).users;
  const history = useHistory()

  const [dataSource, setDataSource] = useState({ labels: [], datasets: [] });
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (user && !user.isAdmin) {
      history.push('/');
    }

    setGraph(props.data);

    setDataSource(props.data);
  }, [user])

  const setGraph = (data) => {
    if(data.mini && data.maxi && data.ruc && data.chassis){
      const graphData = {
        labels: data.mini.labels,
        datasets: [
            {
                label: 'Mini Produced',
                data: data.mini.datasets[0].data,
                backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
            {
              label: 'Maxi Produced',
              data: data.maxi.datasets[0].data,
              backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
            {
              label: 'Ruc Produced',
              data: data.ruc.datasets[0].data,
              backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
            {
              label: 'Chassis Produced',
              data: data.chassis.datasets[0].data,
              backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
        ],
      }
      console.log(graphData);
      setDataSource(graphData);
    }
  }

  const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            lineWidth: 20
        },
        title: {
            display: true,
            text: 'Total Produced',
        },
    },
  };

  return (
    <div>
      {dataSource && <Bar options={graphOptions} data={dataSource} style={{ maxHeight: 500 }} />}
    </div>
  )
}

export default ViewProducedGraph
