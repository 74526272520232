const initialState = {
    chassisList: [],
    loaded: false
}

export const chassisReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_CHASSIS': return {
            ...state,
            chassisList: action.payload
        }
        case 'CHASSIS_LOADED': return {
            ...state,
            loaded: action.payload
        }
        default: return state
    }
}