const initialState = {
    kcmList: [],
    loaded: false
}

export const kcmReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_KCMS': return {
            ...state,
            kcmList: action.payload
        }
        case 'KCMS_LOADED': return {
            ...state,
            loaded: action.payload
        }
        default: return state
    }
}