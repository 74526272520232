import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllMinis = () => async dispatch => {

    dispatch({ type: 'MINIS_LOADED', payload: false })
    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + '/api/mini')
        dispatch({ type: 'GET_ALL_MINIS', payload: response.data })
        dispatch({ type: 'MINIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'MINIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postMini = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + '/api/mini', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Mini posted successfully')

        setTimeout(() => {
            window.location.href = '/mini'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editMini = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/mini/" + values._id, values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Mini edited successfully')

        setTimeout(() => {
            window.location.href = '/mini'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const deleteMini = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/mini", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Mini deleted successfully')

        setTimeout(() => {
            window.location.href = '/mini'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const importExcel = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/import-file", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('File uploaded successfully')

        setTimeout(() => {
            window.location.href = '/mini'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};
