const initialState = {
    rsaList: [],
    loaded: false
}

export const rsaReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_RSA': return {
            ...state,
            rsaList: action.payload
        }
        case 'RSA_LOADED': return {
            ...state,
            loaded: action.payload
        }
        default: return state
    }
}