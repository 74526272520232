import React, { useState, useEffect } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, DatePicker, TimePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { postShipping, editShipping } from '../redux/actions/shippingAction'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { TabPane } = Tabs;
const { TextArea } = Input;

function PostShipping({ match }) {
  const formRef = React.createRef();
  const { shippingList } = useSelector(state => state.shippingReducer)
  const shipping = match.params?.id ? shippingList.find(shipping => shipping._id == match.params.id) : null;


  const [activeTab, setActiveTab] = useState("0")
  const [shippingForm, setShippingForm] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  function onFinalFormFinish(values) {
    const finalObj = { ...values, time: values.time.format('hh:mm A') };
    dispatch(shipping ? editShipping({ ...finalObj, _id: shipping._id }) : postShipping(finalObj));
  }

  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if(shippingList && shippingList.length > 0){
      setShippingForm(shipping)
    }
  }, [])

  useEffect(() => {
    // console.log(shippingForm)
    if(shippingForm){
      formRef.current.setFieldsValue({
        ...shippingForm,
        time: moment(shippingForm.time, 'hh:mm A'),
        date: moment(new Date(shippingForm.date), 'ddd, MMMM Do YYYY'),
      })
    }
  }, [shippingForm])

  useEffect(() => {
    if (user && !user.isAdmin) {
      const menu = user.menu.find(x => x.name.toLowerCase() == 'shipping');
      const rights = menu.rights ?? [];

      if (match.path.includes('edit') && !rights.some(x => x.toLowerCase() == 'edit')) {
        history.push('/');
      }

      if (match.path.includes('post') && !rights.some(x => x.toLowerCase() == 'write')) {
        history.push('/');
      }
    }
  }, [user])

  
  return (
    <div>
      <DefaultLayout>
        <Tabs defaultActiveKey='0' activeKey={activeTab}>
          <TabPane tab={`${shippingForm ? 'Edit' : 'Add'} Shipping`} key='0'>
          <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/') }} 
              ref={formRef}
              initialValues={shippingForm}
              validateMessages={{ required: '${label} is required!' }}>
                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='date' rules={[{ required: true }]} label='Date' initialValue={moment(new Date(), 'ddd, MMMM Do YYYY')}>
                      <DatePicker
                        format='ddd, MMMM Do YYYY'
                        style={{ width: '100%' }}
                        // defaultValue={moment(new Date(), 'ddd, MMMM Do YYYY')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='time' rules={[{ required: true }]} label='Time' initialValue={moment('9:00 AM', 'hh:mm A')}>
                      <TimePicker
                        style={{ width: '100%' }}
                        showSecond={false}
                        format={'hh:mm A'}
                        minuteStep={15}
                        // defaultValue={moment('9:00 AM', 'hh:mm A')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='po' rules={[{ required: true }]} label='PO#'>
                      <Input type='text' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='qtyShippied' rules={[{ required: true }]} label='QTY Shipped'>
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='extendedPrice' rules={[{ required: true }]} label='Extended price of shipment'>
                      <Input type='number' addonBefore="$"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} sm={24}>
                    <Form.Item name='comment' rules={[{ required: false }]} label='Comment'>
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>

                <Button htmlType='submit'>Submit</Button>
                <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
              </Form>
          </TabPane>

        </Tabs>
      </DefaultLayout>
    </div>
  )
}

export default PostShipping
