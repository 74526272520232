import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

function CalendarStrip(props) {
  const [firstDate, setFirstDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  const [weekList, setWeekList] = useState([new Date()]);
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    getWeekDate(new Date());
  }, [])

  const getWeekDate = (date) => {
    var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(date.setDate(first));
    var lastday = new Date(date.setDate(last));
    if (first < 0)
      lastday = new Date(lastday.setMonth(firstday.getMonth() + 1))

    setFirstDate(firstday);
    setLastDate(lastday);
    setWeekList(getDates(firstday, lastday))
    if (props.setFirstLastDate) {
      props.setFirstLastDate({ firstday: firstday, lastday: lastday })
    }
  }

  useEffect(() => {
    if (props.reset) {
      setSelectedDate(null);
    }
  }, [props.reset])

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const getDates = (startDate, stopDate) => {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  const changeWeek = (isPrev) => {
    const takeDate = isPrev ? new Date(firstDate.setDate(firstDate.getDate() - 2)) : lastDate.addDays(2);
    getWeekDate(takeDate);
  }

  const selectDate = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    if (props.setSelectedDate && selectedDate) {
      props.setSelectedDate(selectedDate)
    }
  }, [selectedDate])

  return (
    <div className='calendar-wrapper'>
      <h6>{moment(firstDate).format('MMM DD, YYYY')} - {moment(lastDate).format('MMM DD, YYYY')}</h6>
      <Row className='calendar-strip-wrapper'>
        <div className='calendar-strip-action' onClick={() => changeWeek(true)}>
          <LeftOutlined />
        </div>
        {
          weekList.map(day => {
            return (
              <div
                className={`calendar-strip-date ${moment(day).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY') ? 'current' : ''} ${selectedDate && moment(day).format('MM/DD/YYYY') === moment(selectedDate).format('MM/DD/YYYY') ? 'active' : ''}`}
                key={day.getTime()}
                onClick={() => selectDate(day)}
              >
                <p>{moment(day).format('ddd').toUpperCase()}</p>
                <p>{moment(day).format('DD')}</p>
              </div>
            )
          })
        }
        <div className='calendar-strip-action' onClick={() => changeWeek(false)} >
          <RightOutlined />
        </div>
      </Row>
    </div>
  )
}

export default CalendarStrip
