const initialState = {
  doorsList: [],
  loaded: false
}

export const doorsReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_DOORS': return {
      ...state,
      doorsList: action.payload
    }
    case 'DOORS_LOADED': return {
      ...state,
      loaded: action.payload
    }
    default: return state
  }
}