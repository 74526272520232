import React, { useEffect, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Switch, Checkbox, message } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { registerUser, updateUser } from '../redux/actions/userActions'
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom'

const { TabPane } = Tabs;

const RIGHTS = ['Read', 'Write', 'Edit'];

function PostUser({ match }) {

  const allUsers = useSelector(state => state.usersReducer).users;
  const user = match.params?.id ? allUsers.find(user => user._id == match.params.id) : null;

  const allConstants = useSelector(state => state.usersReducer).constants;
  const menuList = allConstants?.find(x => x.text === "Menu")?.value ?? [];

  const [activeTab, setActiveTab] = useState("0")
  const [isActive, setIsActive] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [userMenu, setUserMenu] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem('user'))
    if (users && !users.isAdmin) {
      history.push('/');
    }
  }, [])


  function onFinalFormFinish(values) {
    const finalObj = { ...values, isActive, isAdmin, menu: isAdmin ? userMenu.map(x => { return { ...x, rights: RIGHTS } }) : userMenu.filter(x => x.rights.length > 0) };
    if (!user && allUsers.some(x => x.username === finalObj.username)) {
      message.error("Username has already been used.")
    } else {
      dispatch(!user ? registerUser(finalObj) : updateUser({ ...finalObj, _id: user._id }));
    }
  }

  const onChange = (checkedValues, menu) => {
    setUserMenu(userMenu.map(x => {
      if (x.name === menu.name) {
        // checkedValues = checkedValues.length === 1 && checkedValues[0] === "Write" ? ['Read', 'Write'] : checkedValues
        x.rights = checkedValues
      }
      return x
    }))
  }

  useEffect(() => {
    if (menuList.length)
      setUserMenu(menuList.map(x => {
        return {
          name: x.name,
          rights: ['Read']
        }
      }))
  }, [menuList])

  useEffect(() => {
    if (user) {
      setUserMenu(menuList.map(x => {
        if (user.menu && user.menu.some(y => y.name === x.name)) {
          return {
            name: x.name,
            rights: user.menu.find(y => y.name === x.name).rights
          }
        } else {
          return {
            name: x.name,
            rights: []
          }
        }
      }))
      setIsActive(user.isActive ?? false)
      setIsAdmin(user.isAdmin ?? false)
    }
  }, [user])

  return (
    <div>
      <DefaultLayout>
        <Tabs defaultActiveKey='0' activeKey={activeTab}>
          <TabPane tab={`${user ? 'Edit' : 'Add'} User`} key='0'>
            <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/') }} initialValues={user} validateMessages={{ required: '${label} is required!' }}>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='firstName' rules={[{ required: true }]} label='First Name' >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='lastName' rules={[{ required: true }]} label='Last Name'>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='phone' label='Phone'>
                    <InputMask mask="999-999-9999" className='ant-input' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='email' label='Email'>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='isAdmin' label='Is Admin'>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={isAdmin}
                      onChange={(checked) => {
                        setIsAdmin(checked);
                        setUserMenu(userMenu.map(x => { return { ...x, rights: checked ? RIGHTS : [RIGHTS[0]] } }))
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='isActive' label='Is Active'>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={isActive}
                      onChange={setIsActive}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='username' rules={[{ required: true }]} label='Username'>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={24}>
                  <Form.Item name='password' rules={[{ required: true }]} label='Password'>
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24} sm={24}>
                  <p>User Menu</p>
                  {menuList.map((x, i) => {
                    return (
                      <Row className='ml-1' key={i}>
                        <Col lg={4}>
                          <label>{x.name}</label>
                        </Col>
                        <Col lg={20}>
                          <Checkbox.Group options={RIGHTS} value={userMenu.find(y => y.name === x.name)?.rights ?? []} onChange={(checkedValue) => onChange(checkedValue, x)} />
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>

              <Button htmlType='submit'>Submit</Button>
              <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
            </Form>
          </TabPane>

        </Tabs>
      </DefaultLayout>
    </div>
  )
}

export default PostUser
