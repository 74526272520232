import React, { useEffect, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, DatePicker, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { postRsa, editRsa } from '../redux/actions/rsaActions'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const typeList = ["Machine related", "Lack of manpower", "Part shortage", "Downstream shortage of parts", "Overproduction", "Waiting for Cabinet", "Others"];

function PostRsa({ match }) {
    const { rsaList } = useSelector(state => state.rsaReducer)
    const rsa = match.params?.id ? rsaList.map((x) => {
        return {
            ...x,
            potentialFailureModes: typeList.find(y => y.toLowerCase() == x.potentialFailureModes?.toLowerCase()) ? x.potentialFailureModes : "Others",
            others: typeList.find(y => y.toLowerCase() == x.potentialFailureModes?.toLowerCase()) ? "" : x.potentialFailureModes,
        }

    }).find(rsa => rsa._id == match.params.id) : null;

    const [activeTab, setActiveTab] = useState("0")
    const [isOthers, setIsOthers] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    function onFinalFormFinish(values) {
        const finalObj = { ...values, potentialFailureModes: values.potentialFailureModes.toLowerCase() == "others" ? values.others : values.potentialFailureModes };
        dispatch(rsa ? editRsa({
            ...finalObj, _id: rsa._id
        }) : postRsa(finalObj));
    }

    const check = (value) => {
        setIsOthers(value.toLowerCase() === "others")
    }

    useEffect(() => {
        if (rsa) {
            setIsOthers(rsa.potentialFailureModes.toLowerCase() === "others")
        }
    }, [rsaList])

    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        if (user && !user.isAdmin) {
            const menu = user.menu.find(x => x.name.toLowerCase() == 'refrigeration sub assembly');
            const rights = menu.rights ?? [];

            if (match.path.includes('edit') && !rights.some(x => x.toLowerCase() == 'edit')) {
                history.push('/');
            }

            if (match.path.includes('post') && !rights.some(x => x.toLowerCase() == 'write')) {
                history.push('/');
            }
        }
    }, [user])

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab={`${rsa ? 'Edit' : 'Add'} Refrigeration Sub Assembly`} key='0'>
                        <Form
                            onFinish={onFinalFormFinish}
                            onReset={() => { history.push('/') }}
                            initialValues={rsa ? { ...rsa, date: moment(new Date(rsa.date), 'ddd, MMMM Do YYYY') } : null}
                            validateMessages={{ required: '${label} is required!' }}
                        >
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='workOrder' rules={[{ required: true }]} label='Work Order#' >
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='model' rules={[{ required: true }]} label='Model' >
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='run' rules={[{ required: true }]} label='Run' >
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='runSize' rules={[{ required: true }]} label='Run Size' >
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='date' rules={[{ required: true }]} label='Date' initialValue={moment(new Date(), 'ddd, MMMM Do YYYY')}>
                                        <DatePicker
                                            format='ddd, MMMM Do YYYY'
                                            defaultValue={moment(new Date(), 'ddd, MMMM Do YYYY')}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='totalStaff' rules={[{ required: true }]} label='Total Staff'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='shiftHours' rules={[{ required: true }]} label='Shift hours'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='adjustedHours' rules={[{ required: false }]} label='Adjusted hours'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='majorChassisAssy' rules={[{ required: true }]} label='Major Chassis Assy'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='subAssemblyTotal' rules={[{ required: true }]} label='Sub Assembly Total'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='comment' rules={[{ required: false }]} label='Comment'>
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='quarantineTags' label='Quarantine Tags'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='problemDescription' label='Problem Description'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='serialPlatesUsedStart' label='Serial Plates Used Start #'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='serialPlatesUsedEnd' label='Serial Plates Used End #'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='downtimeMins' label='Downtime(mins)'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='potentialFailureModes' label='Potential Failure Modes' rules={[{ required: true }]}>
                                        <Select
                                            onChange={check}
                                        >
                                            {typeList.map((x, i) => {
                                                return (
                                                    <Option key={i} value={x}>{x}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>

                                </Col>
                            </Row>
                            {isOthers && <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='others' label='Others'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>}

                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default PostRsa
