import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Select } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'

const { Option } = Select;

function ViewUser() {
  const allUsers = useSelector(state => state.usersReducer).users;
  const history = useHistory()

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: "displayName",
    },
    {
      title: 'Email',
      dataIndex: "email",
    },
    {
      title: 'Username',
      dataIndex: "username",
    },

    // {
    //   title: 'Menu',
    //   dataIndex: "menu",
    // },
    {
      title: 'Is Active',
      render: (text, data) => {
        return <div className="flex" key={data._id}>
          {data.isActive ? <CheckOutlined style={{ color: '#12911b' }} /> : <CloseOutlined style={{ color: '#b50e0e' }} />}
        </div>
      },
    },
    {
      title: 'Is Admin',
      render: (text, data) => {
        return <div className="flex" key={data._id}>
          {data.isAdmin ? <CheckOutlined style={{ color: '#12911b' }} /> : <CloseOutlined style={{ color: '#b50e0e' }} />}
        </div>
      },
    },
    {
      title: 'Action',
      render: (text, data) => {
        return <div className="flex" key={data._id}>
          <EditOutlined onClick={() => { history.push(`/user/edit/${data._id}`) }} />
        </div>
      },
    },
  ];

  useEffect(() => {
    if (allUsers.length) {
      setDataSource(allUsers.map(x => {
        return { ...x, menu: x.menu.map(x => x.name).join(', '), displayName: `${x.firstName ?? ''} ${x.lastName ?? ''}` }
      }))
    }
  }, [allUsers])

  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (user && !user.isAdmin) {
      history.push('/');
    }
  }, [user])

  return (
    <div>
      <DefaultLayout>
        <div className='d-flex justify-content-between'>
          <h3>Users</h3>
        </div>

        <div className="d-flex justify-content-end">
          <Link to='/user/post' className='addBtn'>Add User</Link>
        </div>


        <Table columns={columns} dataSource={dataSource} scroll={{ x: 1300 }} />

      </DefaultLayout>
    </div>
  )
}

export default ViewUser
