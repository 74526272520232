import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constant/api";

export const getAllChassis = () => async dispatch => {

    dispatch({ type: 'CHASSIS_LOADED', payload: false })
    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get(BASE_URL + '/api/chassis')
        dispatch({ type: 'GET_ALL_CHASSIS', payload: response.data })
        dispatch({ type: 'CHASSIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'CHASSIS_LOADED', payload: true })
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postChassis = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + '/api/chassis', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Chassis posted successfully')

        setTimeout(() => {
            window.location.href = '/chassis'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editChassis = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/chassis/" + values._id, values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Chassis edited successfully')

        setTimeout(() => {
            window.location.href = '/chassis'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const deleteChassis = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/chassis", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Chassis deleted successfully')

        setTimeout(() => {
            window.location.href = '/chassis'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const importExcel = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(BASE_URL + "/api/import-file", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('File uploaded successfully')

        setTimeout(() => {
            window.location.href = '/chassis'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};