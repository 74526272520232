const initialState = {
  shippingList: [],
  ftShippingList: [],
  loaded: false
}

export const shippingReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_SHIPPING': return {
      ...state,
      shippingList: action.payload
    }
    case 'GET_ALL_FT_SHIPPING': return {
      ...state,
      ftShippingList: action.payload
    }
    case 'SHIPPING_LOADED': return {
      ...state,
      loaded: action.payload
    }
    default: return state
  }
}